import { PDFDocument } from "pdf-lib";
import downloadjs from 'downloadjs';
import useAxios from "../../../useHooks/useAxios";
import DataContext from "../../../context/DataContext";
import { useContext, useEffect } from "react";

const DownloadOrderPdf = (props) => {
    const { layoutOptions, dispatchLayoutOptions } = useContext(DataContext);

    const { response, loading, error } = useAxios({
        method: 'get',
        url: '/v1/orderPdfData?share=' + layoutOptions[0].share_code,
    });
    
    async function callOrderPDF_API (data) {
        const fieldArr = data.orderPdf;
        const url =  fieldArr?.url;
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const form = pdfDoc.getForm();
        const fields = pdfDoc.getForm().getFields();
        const fieldNames =  [];
        fields.map((f, i) => {
           fieldNames.push(f.getName());
        });        

        fieldArr?.partsData?.map(function (element, index) { 
            let groupName = element.pdf_field_name;
            if(fieldNames.includes(groupName) && element.pdf_index){
                switch (element.pdf_field_type){
                    case "text":
                        const nameField = form.getTextField(groupName);
                        nameField.setText(element.pdf_index);
                    break;

                    case "radioGroup":
                        const radioButtonGroup = form.getRadioGroup(groupName);
                        /* Get all the options of radiogroup */
                        // const options = radioButtonGroup.getOptions()
                        // console.log(groupName, options);
                        radioButtonGroup.select(element.pdf_index);
                    break;
                }
            }
        });
            
        const pdfBytes = await pdfDoc.save() 

        // Trigger the browser to download the PDF document
        downloadjs(pdfBytes, "order-pdf-"+Date.now()+".pdf", "application/pdf"); 
        
        dispatchLayoutOptions({
            type: "ORDER_PDF",
            setvalue: false
        });
    }

    useEffect(()=>{
        if(response){
            callOrderPDF_API(response); 
        }
    }, [response])

	return (<></>)
}

export default DownloadOrderPdf;