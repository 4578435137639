export const searchTree = (fn) => (obj) =>
    Array.isArray(obj)
    ? obj.length === 0
        ? null
        : searchTree (fn) (obj[0]) || searchTree (fn) (obj.slice(1))
    : fn (
        obj,
        () => searchTree (fn) (obj.children || []),
        () => obj
)

export const searchObj = (keyToFind, obj, target) => searchTree (
    (node, next, found) => node[keyToFind] === target ? found () : next(),
) (obj)


/*
    Function Name: arrayEquals(array1, array2)
    Description: 
*/
export function arrayEquals(array1, array2) {
    return Array.isArray(array1) &&
        Array.isArray(array2) &&
        array1.length === array2.length &&
        array1.every((val, index) => val === array2[index]);
}
/* End */


export const findByKey = (mainArr, keyToFind, ValueToCheck, childKeyToFind, childKeyValueToCheck) => {
    let op;
    op = mainArr.map((m) => {
        return (m[keyToFind] === ValueToCheck) ? m : ( m[childKeyToFind] !== childKeyValueToCheck ? findByKey(m.children, keyToFind, ValueToCheck, childKeyToFind, childKeyValueToCheck) : "");
    })

    return (op);
}

export const returnObjFound = (mainArr, keyToFind, keyValueToFind) => {
    let returnObj;
    mainArr.map((r) => {
        r.find(obj => {
            if (obj[keyToFind] === keyValueToFind){
                returnObj = obj
            }
            return null;
        }) 
        return null;       
    })

    return (returnObj);

}


/*
    Function Name: arrayTransform
    parameters: 
        mainArr - 
        keyToFind -
        keyValueToFind -
        ValueToReplace -
        childKeyToFind -
        childKeyValueToCheck - 
    Description: This is a recursive function.
*/
export function arrayTransform(mainArr, keyToFind, keyValueToFind, keyForValueReplace, valueToReplace, childKeyToFind, childKeyValueToCheck) {
    mainArr.forEach(obj => {
        if (obj.hasOwnProperty(keyToFind)){
            if (obj[keyToFind] === keyValueToFind){
                if(Array.isArray(keyForValueReplace) && Array.isArray(valueToReplace)){
                    keyForValueReplace.map((m, i) => (
                        obj[m] = valueToReplace[i]
                    ))
                }else{
                    obj[keyForValueReplace] = valueToReplace;
                }
            }else{
                if (obj.hasOwnProperty(childKeyToFind)){
                    if (obj.children !== childKeyValueToCheck){
                        arrayTransform(obj.children, keyToFind, keyValueToFind, keyForValueReplace, valueToReplace, childKeyToFind, childKeyValueToCheck)
                    }
                }
            }
        }
    });
}

/**
 * Function to add up totals
 */
export function totalWeights(obj, unit){
    let sum=0;    
    obj.map((m, n)=>{
        m.children.map((i, j)=>{
            if(i.component !== "div-color"){
                i.children.map((a,b)=>{
                    if ((a.is_active) && (a.css_classes.includes("active"))){                        
                        sum = sum + parseFloat(a.weight);
                    }
                    return null;
                })
            }
            return null;
        })
        return null;
    });

    return calculateWeight(sum, unit);
}

/**
 * Function to add up totals
 */
export function calculateWeight(value, unit){
    value = (value/1000).toFixed(2); 

    if(unit?.toLowerCase() === 'lbs'){
        value = (value * 2.205).toFixed(2);
    }
    return value;
}

/**
 * Function to show hide options
 */
export function showHideButtons(newObj){
    const hideArr = [];
    newObj?.map((m, n) => (
        m.children?.map((i, j) => {
            i.children?.map((a, b) => {
                if (a.is_active === true) {
                    a.show?.map((o) => {
                        if (o > a.category_id && !hideArr.includes(o)) {
                            arrayTransform(newObj, "category_id", o, "visible", true, "children", "empty");
                        }
                    });
                    a.hide?.map((o) => {
                        if(i.component === 'color-button'){
                            arrayTransform(newObj, "category_id", o, ["visible", "css_classes"], [false, "m-2"], "children", "empty")
                        }
                        else{
                            arrayTransform(newObj, "category_id", o, ["visible", "is_active", "css_classes"], [false, false, "m-2"], "children", "empty")
                        }
                        hideArr.push(o);
                    });
                }
            })
        })
    )) 

    // Make hidden node as inactive 
    newObj.map((m, n)=>{
        m.children.map((i, j)=>{		
            let catId = null;	
            i.children.map((a,b)=>{
                if(a.component === 'button'){
                    if(a.visible === true){						
                        if(catId === null){
                            catId = a.category_id;
                        }
                        if(a.is_active === true){
                            catId = a.category_id;
                        }
                    }
                    
                    // Make node as inactive 
                    arrayTransform(
                        newObj, 
                        "category_id", a.category_id, 
                        ["css_classes", "is_active"], ["m-2", false], 
                        "children", "empty"
                    )
                }
            })

            if(catId){
                // Make node as active
                arrayTransform(
                    newObj, 
                    "category_id", catId, 
                    ["css_classes", "is_active"], ["m-2 active", true], 
                    "children", "empty"
                )
            }
        })
    })
    return newObj;
}