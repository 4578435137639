import { useContext } from 'react';

import DataContext from '../../context/DataContext';

const Div = (p) => {
	const { data } = useContext(DataContext);

	return (
		<div id={p.category_id} className={p.className}>
			{p.children}
			<div>{data}</div>
		</div>
	);
}

export default Div;