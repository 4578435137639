import SunriseLogo from "../assets/images/header_images/sunrise_logo.svg";
import chatIcon from '../assets/images/header_images/chat_icon.svg';
import qsxLogo from '../assets/images/header_images/QS5X_logo.svg';
import ChatIcon from "./ui/ChatIcon";
import { useContext } from "react";
import DataContext from "../context/DataContext";

const Header = () => {
	const { layoutOptions, translate } = useContext(DataContext);

	return (
		<div className={`${!layoutOptions[0].touchpoint && 'touchpoint_disabled'}`}>
			<header className='position-fixed w-100' style={{ zIndex: 1 }}>
				<div className=" position-absolute" style={{ right: "0px", top: "0px",left: "0px", textAlign: 'center'  }}>
					{/* <img src={chatIcon} className='chaticon chat_desktop' alt="chat_icon"/> */}
					{/* <ChatIcon /> */}
					<a href={`${translate[0].logo_link}`} target='_blank'>
						<img src={SunriseLogo} className='logo' alt="sunrise_logo"/>
					</a>
					<a href={`${translate[0].product_link}`} target='_blank'>
						<img src={qsxLogo} className='qsxlogo' alt="sunrise_logo"/>
					</a>
				</div>
			</header>
		</div>
	);
}

export default Header;