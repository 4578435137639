import { useContext } from 'react';

import DataContext from '../../context/DataContext';
import { searchObj, arrayTransform } from '../../helpers/common_helper';

import '../../components/ui/IconsLayout.scss';

export const ToggleButton = (p) => {
	const { objs, dispatch, model, modelDispatch, hotspotsObj, dispatchHotspots, dispatchLayoutOptions } = useContext(DataContext);

	const load_dependent_parts = (obj) => {
		const active_buttons = [];
		const active_ids = [];

		objs?.map((m, n) => (
			m.children?.map((i, j) => {
				active_buttons.push(
					...i.children
						.map(a => a.is_active === true ? a : '')
						.filter(f => f !== '')
				);
				active_ids.push(
					...i.children
						.map(a => a.is_active === true ? a.category_id : '')
						.filter(f => f !== '')
				);
			})
		))
		
		obj.map((parts)=>{
			let flag = true;
			const Depend_ID = [];
			parts.dependency_array.map((depend)=>{
				if(active_ids.indexOf(depend) === -1){
					flag = false;
					
					let parent = depend.split(".");
					let parent_id = parent[0]+'.'+parent[1];
					
					const objID = searchObj("category_id", objs, parent_id);
					Depend_ID.push(
						...objID.children
						.map(m => m.is_active === true ? m.category_id : '')
						.filter(f => f !== '')
					);
				}

			})
			// console.log(Depend_ID);

			if(Depend_ID.length){
				flag = true;
			}

			Depend_ID.map((depend)=>{
				if(parts.dependency_array.indexOf(depend) === -1){
					flag = false;
				}
			})

			parts.is_active = flag;
		})
	}

	const handleClick = (event) => {
		const newObj = objs;
		let newModel = model;
		const newHotspot = hotspotsObj;
		const parentObj = searchObj ("category_id", objs, p.parent_id);

		/* Logic to make options active, in active and show hide options. */
		/* Start */				
		if(p.config.is_active){
			arrayTransform(
				newObj, 
				"category_id", p.category_id, 
				["css_classes", "is_active"], ["m-2", false], 
				"children", "empty"
			);
		}
		else{
			arrayTransform(
				newObj, 
				"category_id", p.category_id, 
				["css_classes", "is_active"], ["m-2 active", true], 
				"children", "empty"
			);
		}

		dispatch({
			type: 'SET_OBJECT',
			obj: newObj
		});
		/* End  */

		
		// Hide unhide hotspots 
		newHotspot.map((h) =>{
			let hActive = true;
			if(h.main_cat.includes(parseInt(parentObj.parent_id))){
				newObj.map((m, n)=>{
					m.children.map((i, j)=>{
						i.children.map((a,b)=>{
							if(h.dependency_array.includes(a.category_id) && hActive){
								hActive = a.is_active;
							}
						})
					})
				})
				h.is_active = hActive;
			}
		})
		
		dispatchHotspots({ type: 'SET_HOTSPOT', obj: newHotspot });

		/* Logic to Show hide GLB models based on options click. */
		/* Start */
		load_dependent_parts(newModel);

		modelDispatch({
			type: "SET_MODEL",
			model: newModel
		});
		/* End */
	}

	return (
		<>
			<button id={p.category_id} onClick={event => handleClick(event)} className={`w-100 full_width_button ${p.className} ${(p.config.visible) ? '' : 'b-hide'}`}>
				{p.children}
			</button>
		</>
	);
}

