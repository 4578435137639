import { useEffect, useContext } from "react";
import { useSearchParams } from 'react-router-dom';

import Model from "../components/models/Model";
import DataContext from '../context/DataContext';

import { totalWeights } from '../helpers/common_helper';
import useAxios from "../useHooks/useAxios";
import LoadingBlock from "../components/ui/LoadingBlock";

const Homepage = () => {
	const [queryParameters] = useSearchParams();
	const lang = "lang=" + (queryParameters.get("lang") ? queryParameters.get("lang") : "");
	const shareParam = "&share=" + (queryParameters.get("share") ? queryParameters.get("share") : "");
	const country = '&country=' + (queryParameters.get("country") ? queryParameters.get("country") : "");
	const visualizer =  "&visualiser=" + (queryParameters.get("visualizer") ? queryParameters.get("visualizer") : "");

    const { dispatch, modelDispatch, dispatchTranslate, dispatchLayoutOptions, dispatchHotspots, mainPartsDispatch } = useContext(DataContext);

    const { response, loading, error } = useAxios({
        method: 'get',
        url: '/v1/configuratorParts-3?' + lang + visualizer + shareParam,
    });
    const { response: response2, loading: loading2, error: error2 } = useAxios({
        method: 'get',
        url: '/v1/configuratorPartsList-1?' + lang +  visualizer + shareParam,
    });
    const { response: response3, loading: loading3, error: error3 } = useAxios({
        method: 'get',
        url: '/v1/staticTranslation?' + lang + country + visualizer + shareParam,
    });
    const { response: response4, loading: loading4, error: error4 } = useAxios({
        method: 'get',
        url: '/v1/hotspotList?' + lang + visualizer + shareParam,
    });

    useEffect(() => {
        dispatchLayoutOptions({ type: 'SET_VISUALISER', setvalue: queryParameters.get("visualizer") });
        
        if (response !== null) {
            let newObj = response?.configurator?.categories;
        
            dispatch({ type: 'SET_OBJECT', obj: newObj });
        }

        if (response2 !== null) {
            let newModel = response2?.configurator;
            
            modelDispatch({ type: "SET_MODEL", model: newModel });

            // pushing only active default parts in an array. This is done to speed up Loading on mobile
            const modelDemo = [];
            newModel?.map((m, mIndex) => {
                if (m.is_active === true) modelDemo.push(m);
            })
            newModel?.map((m, mIndex) => {
                if (m.type === "safety") modelDemo.push(m);
            })
            mainPartsDispatch({ type: "SET_MAIN_PARTS", main_parts: modelDemo });
        }

        if (response3 !== null) {
            dispatchTranslate({ type: 'SET_TRANSLATE', translate: response3?.configurator });
        }

        if (response4 !== null) {
            dispatchHotspots({ type: 'SET_HOTSPOT', obj: response4?.configurator });
        }
    }, [response, response2, response3, response4]);
    
    return (
        <>
            <LoadingBlock />
            { error && error2 && error3 && error4 && "Error" }
            { !loading && !loading2 && !loading3 && !loading4 && <Model /> }
        </>
    );
}
 
export default Homepage;