import { useContext , useEffect, useState } from 'react'
import React, { Suspense } from "react";
import { Canvas } from '@react-three/fiber'
import uuid from 'react-uuid';
import { useGLTF, useProgress } from "@react-three/drei";
import { ResizeObserver } from '@juggle/resize-observer';

import Lights from '../lights/Lights';
import Cameras from '../cameras/Cameras';
import Environments from '../environments/Environments';
import Floor from '../Floor';
import ButtonLayout from '../ui/ButtonLayout';
import IconsLayout from '../ui/IconsLayout';
import SummaryComponent from '../ui/blockComponents/SummaryComponent';
import WeightComponent from '../ui/blockComponents/WeightComponent';

import DataContext from '../../context/DataContext';
import Hotspots from './Hotspots';

import SSAO_Module from '../SSAO';
import HotspotModal from './HotspotModal';
import ButtonConfigure from '../ui/ButtonConfigure';
import { Helmet } from 'react-helmet-async';
import PartsMesh from './PartsMesh';
import { searchObj, totalWeights } from '../../helpers/common_helper';
// import { Perf } from 'r3f-perf';

export default function Model() {
	const { objs, model, hotspotsObj, layoutOptions, mainParts, mainPartsDispatch , dispatchLayoutOptions, translate } = useContext(DataContext);	

	const { active, progress } = useProgress();
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	
    useEffect(() => {
        dispatchLayoutOptions({ type: "SET_WEIGHTS_TOTAL", setvalue:  totalWeights(objs, translate[0].weight_unit) })
    }, [objs])

	useEffect(()=>{
		if (active === false && progress > 98){
			setTimeout(()=>{
				dispatchLayoutOptions({ type: 'SET_PARTS_LOADING', setvalue: true });
			}, 300)
		}
		if (active === true && progress === 0){
			dispatchLayoutOptions({ type: 'SET_PARTS_LOADING', setvalue: false });
		}
	}, [active, layoutOptions[0].isPartsLoaded])
	

	const loadPart = async (path) => {
		return new Promise(async (resolve) => {
			await useGLTF.preload(path)
			await resolve();
		});
	}


	const changeGLB = (recordId, objGLB) => {
		const editModel = [...mainParts];
		recordId.map((m, i) => {
			editModel[m] = objGLB[i];
			loadPart(editModel[m]?.part_url)
			let myInterval = setInterval(()=>{
				if (layoutOptions[0].isPartsLoaded){
					clearInterval(myInterval);
					mainPartsDispatch({ type: "SET_MAIN_PARTS", main_parts: editModel });
					setTimeout(()=>{

						const f55 = searchObj("category_id", objs, '8.1.1');
						
						if(f55?.is_active && layoutOptions[0].visualiser !== "2"){
							layoutOptions[0].cam?.current?.setLookAt(-11, 3, 2, 0, 3, 2, true);
							dispatchLayoutOptions({ type: 'F55_ACTIVE', setvalue: true });
							dispatchLayoutOptions({ type: 'F55_FLOOR', setvalue: -0.65 });

							if (windowWidth < 1025 && windowWidth > 769) {
								layoutOptions[0].cam?.current?.reset(true);
								layoutOptions[0].cam?.current?.setLookAt(-13, 5, 15, 0, -1, 2, true);
							}
							else if (windowWidth < 769) {
								layoutOptions[0].cam?.current?.reset(true);
								layoutOptions[0].cam?.current?.setLookAt(-18, 5, 17, 0, -1, 2, true);
							}
						}

						dispatchLayoutOptions({ type: 'SET_PARTS_LOADING', setvalue: false });
						dispatchLayoutOptions({ type: 'SET_LOADING', setvalue: false });
					}, 500);
				}
			}, 500);
		})
	}

	useEffect(()=>{
		const init = () => {
			let indexArry = [];
			let modelArry = [];
			let modelDemo = [];
			dispatchLayoutOptions({ type: 'SET_PARTS_LOADING', setvalue: false });
			dispatchLayoutOptions({ type: 'SET_LOADING', setvalue: true });
			try {
				model?.map((m, mIndex) => {
					if (m.is_active === true){
						modelDemo.push({...m, color:"", material_name: ""});
					}
				})
		
				for (var i = 0; i < mainParts.length; i++) {
					if (JSON.stringify(mainParts[i]) !== JSON.stringify(modelDemo[i])) {
						indexArry.push(i)
						modelArry.push(modelDemo[i])
					}
				}
			} catch (exceptionVar) {
				console.log ("Error preloading GLB or GLTF files");
			} finally {
				changeGLB(indexArry, modelArry)
			}
		}
		init();
	}, [model]);

	return (
		<div>
			{/* <Helmet prioritizeSeoTags>
				<title>{translate[0].html_title}</title>
				<meta name="description" content={`${translate[0].meta_desc}`} />
				<meta name="keywords" content="..." />
				<meta property="og:url" content={`${translate[0].logo_link}`} />
				<meta property="og:title" content={`${translate[0].html_title}`}/>
				<meta property="og:description" content={`${translate[0].meta_desc}`} />
				<meta property="og:image" content={`${process.env.REACT_APP_WEB_URL}QS5-X.jpg`} />
				<meta property="og:image:url"  content={`${process.env.REACT_APP_WEB_URL}QS5-X.jpg`} />
				<meta name="twitter:card" content="summary" />
				<meta property="twitter:image" content={`${process.env.REACT_APP_WEB_URL}QS5-X.jpg`} />
			</Helmet> */}

			<Canvas
				style={{ zIndex: 0, position: 'absolute' }}
				camera={{ fov: 75, position: [5, -1, 9] }}
				id="three-canvas-container"
				dpr={[1, 2]}
				blur={1}
				shadows={true}
				flat={true}
				frameloop="always"
				resize={{ polyfill: ResizeObserver }}
				gl={{ preserveDrawingBuffer: true, physicallyCorrectLights: true, antialias: true, multisampling: 100 }}
				className={`${!layoutOptions[0].touchpoint && 'touchpoint_disabled'}`}
			>
				<Suspense fallback={null}>
					{/* <Perf position="bottom-left" style={{left: '30%'}} /> */}

					<Cameras />
					<Lights />
					<Environments blur={0} />

					{/* <SSAO_Module /> */}
					
					<group visible={!layoutOptions[0].initialLoading}>
						{
							mainParts?.map((m, mIndex) => 
								{return m?.is_active &&
									<PartsMesh 
										key={uuid()}
										url={m?.part_url}
										visible={m.is_active}
										scale={10}
										part_id = {m.id}
										colors = {m.colors}
										materials = {m.materials}
										metalness = {m.metalness}
										roughness = {m.roughness}
										texture = {m.texture}
										repeat_x = {m.repeat_x}
										repeat_y = {m.repeat_y}
									/>
								}
							)
						}

						{
							!layoutOptions[0].toggle_env ?
								<>
									<Floor position={[0, -0.3, 0]} url={`${process.env.REACT_APP_WEB_URL}/floor/roundshadow.png`} scale={layoutOptions[0].show_floor} x={1.3} y={2.0}  />
								</>
							:
								<>
									<Floor position={[0, -0.3, 0]} url={`${process.env.REACT_APP_WEB_URL}/floor/roundshadow.png`} scale={11} x={1.3} y={2.0}  />
									<Floor position={[0, layoutOptions[0].f55_floor , 0]} /> 
								</>
						}

						{
							hotspotsObj.map((h, i) =>
								{return (h.is_active && !layoutOptions[0].hide_hotspots) && <Hotspots key={uuid()}  posi_value={[h.x, h.y, h.z]} mat_color={'#ee6100'} click_val={h.id} prop={h} name={h.name}/>}
							)
						}
					</group>
				</Suspense>
			</Canvas>

			{
				hotspotsObj.map((h, i) =>
					{return layoutOptions[0].hotspots && layoutOptions[0].hotspots_btn === h.id && <HotspotModal key={uuid()} />}
				)
			}

			<IconsLayout />
			<WeightComponent />
			<SummaryComponent />
			<ButtonLayout />
			<ButtonConfigure hide={(layoutOptions[0].summary || layoutOptions[0].weights) ? 'show' : 'hide'} />
		</div>
	)
}