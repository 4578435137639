import { createElement } from 'react'
//import uuid from 'react-uuid';

import Div from './Div';
import { Button } from './Button';
import { ColorButton } from './ColorButton';
import { ToggleButton } from './ToggleButton';

const keyComponentMap = {
	"div": Div,
	"button": Button,
	"color-button": ColorButton,
	"toggle-button": ToggleButton
}

export const renderComponent = (config, parent_id) => {

	return createElement(
		keyComponentMap[config.component],
		{
			config						:				config,
			className					:				config.css_classes,
			key							: 				config.category_id,
			category_id					:				config.category_id,
			parent_id					: 				parent_id
		},
		config.children &&
		(typeof config.children === 'string'
			? config.category_display_name
			:
			<>
				{config.category_display_name}<br />
				{config.children.map((c) => renderComponent(c, config.category_id))}
			</>
		)
	);
}

//export default renderComponent;