import './IntroLayout.scss';
import loader from '../../assets/images/loader_image.svg';
import qsxLogo from '../../assets/images/header_images/QS5X_logo.svg';
import { useProgress } from '@react-three/drei';
import DataContext from '../../context/DataContext';
import { useContext } from 'react';

function truncator(numToTruncate, intDecimalPlaces) {
    var numPower = Math.pow(10, intDecimalPlaces); // "numPowerConverter" might be better
    return ~~(numToTruncate * numPower) / numPower;
}

const LoaderLayout = ({ ...props }) => {
    const { progress } = useProgress();
    const { translate } = useContext(DataContext);

    return (
        <div className='intro_wrapper loader_wrapper'>
            <div className='inner_wrapper'>
                <div className='loader_logo'>
                    <img src={qsxLogo} alt="qsx_logo" />
                </div>
                <div className='loader_image'>
                    <img src={loader} alt="loader_image" />
                    <div className="loader-circle">
                        <div className="circular-progress" style={{ "--progress-deg": `${3.6 * truncator(progress, 0)}deg` }}>
                            <span className="progress-value">{truncator(progress, 0)}%</span>
                        </div>
                    </div>
                </div>
                <div className='loader_title'>
                    {translate[0].loader_text}
                </div>
                {/* <div className='loader_info'>
                    {translate[0].Loader_help_me_chose}
                </div> */}
            </div>
        </div>
    )
}

export default LoaderLayout