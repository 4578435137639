import React, { useContext } from 'react';
import DataContext from '../../../context/DataContext';

//icons
import facebook from '../../../assets/images/social_media_icons/facebook.svg';
import instagram from '../../../assets/images/social_media_icons/instagram.svg';
import linkedin from '../../../assets/images/social_media_icons/linkedin.svg';
import tiktok from '../../../assets/images/social_media_icons/tik-tok.svg';
import whatsapp from '../../../assets/images/social_media_icons/whatsapp.svg';
import twitter from '../../../assets/images/social_media_icons/twitter-x.png';
import { useSearchParams } from 'react-router-dom';
import { Base64 } from 'js-base64';

const SocialMediaLayout = () => {

    const {layoutOptions, translate } = useContext(DataContext);
	const [queryParameters] = useSearchParams();
    let urlParams = '';
    const qr_code =layoutOptions[0].share_code;
    
    queryParameters.forEach((value, key) => {
        if(key !== 'share'){
            urlParams = urlParams+'&'+key+'='+value;
        }
    });
    if(urlParams != ''){
        urlParams = urlParams.slice(1)+'&share='+qr_code;
    }
    else{
        urlParams = 'share='+qr_code;
    }

    const handleShare = (type) => {
        const socialArr = [];
        let url = '';
        socialArr.push(translate[0].meta_desc);
        socialArr.push(translate[0].html_title);
        socialArr.push(translate[0].logo_link);
        socialArr.push(layoutOptions[0].share_img);
        socialArr.push(process.env.REACT_APP_WEB_URL);
        socialArr.push(urlParams);

        switch (type) {
            case 'linkedin':
                url = 'http://www.linkedin.com/shareArticle?mini=true&url=';
                break;
            case 'instagram':
                url = 'https://www.instagram.com/?url=';
                break;
            case 'whatsapp':
                url = 'https://api.whatsapp.com/send?text=';
                break;
            case 'tiktok':
                url = 'https://www.tiktok.com/login/?url=';
                break;
            case 'twitter':
                url = 'http://www.twitter.com/intent/tweet?url=';
                break;
            case 'facebook':
                url = 'https://www.facebook.com/sharer/sharer.php?u=';
                break;
            default:
                break;
        }

        console.log(process.env.REACT_APP_WEB_URL +'/social/?share='+Base64.encodeURI(socialArr.toString()));

        window.open(
            url + process.env.REACT_APP_WEB_URL +'/social/?share='+Base64.encodeURI(socialArr.toString()),
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    return (
            <div className='social-icon-block'>
                { translate[0]?.social_media[0] ? <a onClick={ () => {handleShare('linkedin')}} ><img src={linkedin} alt='linkedin' /></a> : ''}
                { translate[0]?.social_media[1] ? <a onClick={ () => {handleShare('instagram')}}><img src={instagram} alt='instagram' /></a> : ''}
                { translate[0]?.social_media[2] ? <a  onClick={ () => {handleShare('whatsapp')}}  target='_blank'><img src={whatsapp} alt='whatsapp' /></a> : ''}
                { translate[0]?.social_media[3] ? <a onClick={ () => {handleShare('tiktok')}} ><img src={tiktok} alt='tiktok' /></a> : ''}
                { translate[0]?.social_media[4] ? <a onClick={ () => {handleShare('twitter')}}><img src={twitter} alt='twitter' /></a> : ''}
                { translate[0]?.social_media[5] ? <a onClick={ () => {handleShare('facebook')}}><img src={facebook} alt='facebook' /></a> : ''}
            </div>
    )
}

export default SocialMediaLayout