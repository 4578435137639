import "./IconsLayout.scss";
import * as THREE from 'three';
import closeicon from '../../assets/images/close-icon.png';
import rightarrow from '../../assets/images/icons_layout_mobile_images/right_arrow.svg'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AllCollapseExample from "./Accordion";

import { createRef, Fragment, useContext, useEffect, useRef, useState } from "react";
import DataContext from "../../context/DataContext";

import { arrayTransform, showHideButtons } from '../../helpers/common_helper';
import Terms from "./Terms";

import upperNotch from '../../assets/images/icons_layout_images/upper_notch.svg';
import lowerNotch from '../../assets/images/icons_layout_images/lower_notch.svg';

const IconsLayout = () => {
    const { objs, dispatch, hotspotsObj, dispatchHotspots, layoutOptions, dispatchLayoutOptions, translate } = useContext(DataContext);
    const name = useRef('');
    const [panelAnimation, setPanelAnimation] = useState('hide');
    const [scroll, setScroll] = useState(true);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const { DEG2RAD } = THREE.MathUtils

    const ref = useRef(null);

    const ref2 = useRef(null);

    function changeAngleDesktop(x, y, z, tx, ty, tz, isAnimate) {
        layoutOptions[0]?.cam?.current?.setLookAt(x, y, z, tx, ty, tz, isAnimate);
    }

    function changeAngleMobile(mob_x, mob_y, mob_z, mob_tx, mob_ty, mob_tz, mob_isAnimate) {
        if (windowWidth < 769) {
            layoutOptions[0]?.cam?.current?.setLookAt(mob_x, mob_y, mob_z, mob_tx, mob_ty, mob_tz, mob_isAnimate);
        } else { }
    }

    useEffect(() => {
        setPanelAnimation((objs.length === 0) ? 'hide' : layoutOptions[0].initialLoading ? 'hide' : 'show');

        const handleWindowResize = () => {
            setWindowHeight(window.innerHeight);
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, [objs, layoutOptions[0].initialLoading])

    const handlebtnClick = (value) => {

        name.current = "disable_effect";
        dispatchLayoutOptions({
            type: "SET_ICON_BTN",
            setvalue: value
        });

        const newObj = objs;

        /* Logic to show hide options. */
        /* Start */
        newObj?.map((m, n) => (
            m.children?.map((i, j) => {
                i.children?.map((a, b) => {
                    if (a.is_active === true) {
                        a.hide?.map((o) => {
                            arrayTransform(newObj, "category_id", o, "visible", false, "children", "empty");
                        });
                        a.show?.map((o) => {
                            if (o > a.category_id) {
                                arrayTransform(newObj, "category_id", o, "visible", true, "children", "empty");
                            }
                        })
                    }
                })
            })
        ))

        dispatch({
            type: 'SET_OBJECT',
            obj: newObj
        });
        /* End */

        const newHotspot = hotspotsObj;

        // Logic to hide/unhide hotspots
        newHotspot.map((h) => {
            let hActive = true;
            if (h.main_cat.includes(value)) {
                objs.map((m, n) => {
                    if (h.main_cat.includes(parseInt(m.category_id))) {
                        m.children.map((i, j) => {
                            i.children.map((a, b) => {
                                if (h.dependency_array.includes(a.category_id) && hActive) {
                                    hActive = a.is_active;
                                }
                            })
                        })
                        h.is_active = hActive;
                    }
                })
            }
            else {
                h.is_active = false;
            }
        })

        dispatchHotspots({
            type: 'SET_HOTSPOT',
            obj: newHotspot
        });

        switch (value) {
            case 0:
                changeAngleDesktop(-5, 3, 6, -0.5, 3, 0, true);
                changeAngleMobile(-4, 3, 6, 0.5, 3, 0, true);
                break;

            case 1:
                changeAngleDesktop(3, 7, 8, 0, 3, 0, true);
                changeAngleMobile(2, 7, 6, -0.5, 3, 0, true);
                break;

            case 2:
                changeAngleDesktop(-2, 6, -10, 0, 3, 0, true);
                changeAngleMobile(-2, 6, -8, 1, 3, 0, true);
                break;

            case 3:
                changeAngleDesktop(-5, 7, 6, 0.5, 3, 0, true);
                changeAngleMobile(-6, 7, 4, -1, 3, 0, true);
                break;

            case 4:
                changeAngleDesktop(4, 3.5, 8, -0.2, 3, 0, true);
                changeAngleMobile(4, 3.5, 6, -1, 3, 0, true);
                break;

            case 5:
                changeAngleDesktop(8, 3, 4, -0.2, 3, 0, true);
                changeAngleMobile(8, 3, 2, 0, 3, 0, true);
                break;

            case 6:
                changeAngleDesktop(-9, 3, 3, -1, 3, 0, true);
                changeAngleMobile(-8, 3, 2, -2, 3, 0, true);
                break;

            case 7:
                changeAngleDesktop(6, 4, 5, -0.5, 3, 0, true);
                changeAngleMobile(6, 4, 4, 0.5, 3, 0, true);
                break;

            case 8:
                changeAngleDesktop(0, 3, -8, 1, 3, 0, true);
                changeAngleMobile(0, 3, -8, 0, 3, 0, true);
                break;

            default:
                break;
        }
    }

    const handleTop = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        setScroll(true);
    }
    const handleDown = () => {
        ref2.current?.scrollIntoView({ behavior: 'smooth' });
        setScroll(false);
    }

    const icons_ref = useRef([]);
    icons_ref.current = objs.map((_, i) => icons_ref.current[i] ?? createRef());

    const content_ref = useRef([]);
    content_ref.current = objs.map((_, i) => content_ref.current[i] ?? createRef());

    const notch = useRef();
    const ripple = useRef();
    const [containerId, setContainerId] = useState(0);
    const [showCat, setShowCat] = useState();

    const icon_click_resize = (idx) => {
        notch.current.style.top = icons_ref?.current[idx]?.current?.offsetTop + "px";
        setContainerId(idx)
    }

    const [rippleState, setRippleState] = useState("");
    const showRipple = () => {
        if (!layoutOptions[0].initialLoading) {
            setTimeout(() => {
                ripple.current.style.top = icons_ref?.current[0]?.current?.offsetTop + "px";
                ripple.current.style.width = icons_ref?.current[0]?.current?.clientWidth + "px";
                ripple.current.style.height = icons_ref?.current[0]?.current?.clientWidth + "px";

                setRippleState("effect_display");
            }, 1500)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", icon_click_resize(containerId));
        showRipple();
    })

    const icon_click = (idx) => {
        content_ref.current[idx].current.style.top = icons_ref.current[idx].current.offsetTop + "px";

        dispatchLayoutOptions({
            type: "SET_ICON_EFFECT",
            setvalue: false
        })

        dispatchLayoutOptions({
            type: "TERMS_PANEL",
            setvalue: true
        });

        icon_click_resize(idx)
        setShowCat("showCat")

        const newObj = structuredClone(objs);

        // showHideButtons() to show and hide buttons
        dispatch({ type: 'SET_OBJECT', obj: showHideButtons(newObj) });

        const newHotspot = hotspotsObj;

        // Logic to hide/unhide hotspots
        newHotspot.map((h) => {
            let hActive = true;
            if (h.main_cat.includes(idx)) {
                objs.map((m, n) => {
                    if (h.main_cat.includes(parseInt(m.category_id))) {
                        m.children.map((i, j) => {
                            i.children.map((a, b) => {
                                if (h.dependency_array.includes(a.category_id) && hActive) {
                                    hActive = a.is_active;
                                }
                            })
                        })
                        h.is_active = hActive;
                    }
                })
            }
            else {
                h.is_active = false;
            }
        })

        dispatchHotspots({
            type: 'SET_HOTSPOT',
            obj: newHotspot
        });

        switch (idx) {
            case 0:
                changeAngleDesktop(-5, 3, 6, 0, 3, 0, true);
                changeAngleMobile(-4, 3, 6, 0, 3, 0, true);
                break;

            case 1:
                changeAngleDesktop(3, 7, 8, 0, 3, 0, true);
                changeAngleMobile(2, 7, 6, 0, 3, 0, true);
                break;

            case 2:
                changeAngleDesktop(-2, 6, -10, 0, 3, 0, true);
                changeAngleMobile(-2, 6, -8, 0, 3, 0, true);
                break;

            case 3:
                changeAngleDesktop(-5, 7, 6, 0, 3, 0, true);
                changeAngleMobile(-6, 7, 4, 0, 3, 0, true);
                break;

            case 4:
                changeAngleDesktop(4, 3.5, 8, 0, 3, 0, true);
                changeAngleMobile(4, 3.5, 6, 0, 3, 0, true);
                break;

            case 5:
                changeAngleDesktop(8, 3, 4, 0, 3, 0, true);
                changeAngleMobile(8, 3, 2, 0, 3, 0, true);
                break;

            case 6:
                changeAngleDesktop(-9, 3, 3, 0, 3, 0, true);
                changeAngleMobile(-8, 3, 2, 0, 3, 0, true);
                break;

            case 7:
                changeAngleDesktop(6, 4, 5, 0, 3, 0, true);
                changeAngleMobile(6, 4, 4, 0, 3, 0, true);
                break;

            case 8:
                changeAngleDesktop(0, 3, -8, 0, 3, 0, true);
                changeAngleMobile(0, 3, -8, 0, 3, 0, true);
                break;

            default:
                break;
        }
    }

    const handleClose = () => {
        setShowCat("")
        name.current = "";
        const newHotspot = hotspotsObj;

        dispatchLayoutOptions({
            type: "SET_ICON_EFFECT",
            setvalue: true
        })

        dispatchLayoutOptions({
            type: "SET_MOBILE_ICON_LAYOUT",
            setvalue: false
        });

        // Make default hotspots as active Start
        newHotspot.map((h, i) => {
            h.is_active = h.is_default;
        })

        dispatchHotspots({
            type: "SET_HOTSPOT",
            obj: newHotspot
        })

        dispatchLayoutOptions({
            type: "TERMS_PANEL",
            setvalue: true
        });

        // Make default hotspots as active End

        if (windowWidth < 1025 && windowWidth > 769) {
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-13, 5, 15, 0, 3, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true)
        }
        else if (windowWidth < 769) {
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-18, 5, 17, 0, 3, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true)
        } else {
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-11, 3, 2, 0, 3, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true)
        }

    }

    const handleArrow = () => {
        dispatchLayoutOptions({
            type: "SET_MOBILE_ICON_LAYOUT",
            setvalue: true
        });

        dispatchLayoutOptions({
            type: "SET_ICON_EFFECT",
            setvalue: false
        });

        if (windowWidth < 1025 && windowWidth > 769){
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-10, 5, 12, 0, -1, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-13, 5, 15, 0, -1, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-10, 5, 12, 0, -1, 0, true)        
        }  
        else if (windowWidth < 769) {
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, -1, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-18, 5, 17, 0, -1, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, -1, 0, true)
        }
    }

    return (
        <>
            <div className={`icons-ripple mob-effect ${rippleState}`}>
                <div ref={ripple} className={`ripple-effect ${!layoutOptions[0].iconEffect && 'disable_effect'}`}></div>
            </div>
            <div className={`parts-config-panel ${layoutOptions[0].iconLayout ? panelAnimation : 'hide'} ${showCat}`} >
                <div className={`icons-wrap ${layoutOptions[0].mobile_icon_layout ? 'mob-icon-wrap' : ''}`}>
                    {
                        objs.map((obj, i) => (
                            <div key={i} ref={icons_ref.current[i]} className={`icons icons-${i} ${name.current}`} onClick={() => { icon_click(i) }} title={obj.category_display_name}>
                                <img style={{ width: '100%', height: 'auto' }} src={obj.icon} alt="" />
                            </div>
                        ))
                    }
                </div>

                <div className="categories-wrap">
                    <div ref={notch} className="icons-notch">
                        <div className="upper"><img src={upperNotch} alt="" /></div>
                        <div className="lower"><img src={lowerNotch} alt="" /></div>
                    </div>

                    {
                        objs.map((obj, i) => (
                            <div key={i} ref={content_ref.current[i]} className={`block_${i} categories-container cat-container-${i} ${i === containerId ? "active" : ""}  ${layoutOptions[0].mobile_icon_layout ? 'align-bottom' : ''} `}>
                                <div className="innerWrap">
                                    <div className={`icons_title_area ${!layoutOptions[0].touchpoint && 'touchpoint_disabled'}`}>
                                        <div className="d-none-desktop pe-2">
                                            <div key={i} className={`icons icons-${i}`} onClick={() => { icon_click(i) }} title={obj.category_display_name}>
                                                <img style={{ width: '100%', height: 'auto' }} src={obj.icon} alt="" />
                                            </div>
                                        </div>
                                        <FontAwesomeIcon icon={faAngleLeft} onClick={handleClose} className="icon_arrow_left d-none-mob" style={{ cursor: 'pointer' }} />
                                        <div className="icon_step">
                                            <div className="step">{translate[0].Step} {i + 1}</div>
                                            <div className="icon_frame" title={obj.category_display_name}>{obj.category_display_name}</div>
                                        </div>
                                        <div className="icon_check d-none-mob">
                                            <img className='closeicon' id="closeMenu" style={{ cursor: 'pointer' }} onClick={handleClose} src={closeicon} alt='close' />
                                        </div>

                                        <div className="icon_check d-none-desktop">
                                            <img className={`right_arrow ${layoutOptions[0].mobile_icon_layout ? 'd-none' : ''}`} style={{ cursor: 'pointer' }} onClick={handleArrow} src={rightarrow} alt='right arrow' />
                                            <img className={`close_icon ${layoutOptions[0].mobile_icon_layout ? '' : 'd-none'}`} style={{ cursor: 'pointer' }} onClick={handleClose} src={closeicon} alt='close icon' />
                                        </div>

                                    </div>
                                    <div className="icons_content_area moz_scroll">
                                        <AllCollapseExample
                                            children={obj.children}
                                        />
                                    </div>
                                    <Terms />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
};

export default IconsLayout;
