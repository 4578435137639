import overlayImage from '../../assets/images/overlay_image.png'

const Overlay = () => {
    return (
        <div id="overlay">
            <div id="overlay_image"><img src={overlayImage} alt='overlay image' /></div>
        </div>
    )
}

export default Overlay