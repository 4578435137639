import React, { useContext } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

import qx5x from "../../assets/images/QS5X_pdf.png";
import sunrise from "../../assets/images/sunrise_pdf.png";
import PublicSansThin from "../../assets/fonts/public-sans-v14-vietnamese_latin-ext_latin-300.ttf";
import PublicSansRegular from "../../assets/fonts/public-sans-v14-vietnamese_latin-ext_latin-regular.ttf";
import PublicSansMedium from "../../assets/fonts/public-sans-v14-vietnamese_latin-ext_latin-500.ttf";
import PublicSansSemiBold from "../../assets/fonts/public-sans-v14-vietnamese_latin-ext_latin-600.ttf";
import PublicSansBold from "../../assets/fonts/public-sans-v14-vietnamese_latin-ext_latin-700.ttf";
import DataContext from '../../context/DataContext';



Font.register({
    family: 'Public Sans', fonts: [
        { src: PublicSansThin, fontWeight: 300 },
        { src: PublicSansRegular, fontWeight: 400 },
        { src: PublicSansMedium, fontWeight: 500 },
        { src: PublicSansSemiBold, fontWeight: 600 },
        { src: PublicSansBold, fontWeight: 700 },
    ],
    format: 'truetype',
});

const styles = StyleSheet.create({
    details_wrapper: {

        padding: 25,
        fontWeight: 400,
        fontStyle: 'normal',
        fontFamily: 'Public Sans',
        width: '100vw'
    },
    page: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 30,
    },
    section: {
        width: '20%',
        backgroundColor: '#cccccc',
    },
    singletext: {
        marginBottom: 10,
    },
    details_title: {
        marginBottom: 10,
        marginTop: 10,
    },
    details_titleH1: {
        margin: '0 0 10px',
        color: '#000000',
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '25px',
        fontFamily: 'Public Sans',
    },
    details_outer_wrapper: {
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        padding: '10px 0 5px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    edit_col: {
        width: '100%',
    },
    view_category_name: {
        width: '70%',
    },
    details_outer_wrapper2: {
        borderBottom: '1px solid #ccc',
        padding: '10px 0 5px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    details_first_column: {
        paddingRight: '10px',
        fontSize: '10px',
        color: '#000000',
        fontWeight: 600,
        fontStyle: 'normal',
        fontFamily: 'Public Sans',
        width: '200px',
    },
    details_second_column: {
        marginRight: '5px',
        display: 'flex',
        flexDirection: 'row',
        width: '350px',
    },
    details_third_column: {
        marginBottom: '0.5px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '210px',
    },
    details_second_column_text: {
        display: 'block',
        marginBottom: '10px',
        color: '#0b0b0b',
        fontWeight: '400',
        fontSize: '10px',
        fontFamily: 'Public Sans',
    },
    details_third_column_text: {
        color: '#0b0b0b',
        fontWeight: '400',
        fontSize: '10px',
        width: '90%',
    },
    color: {
        position: 'relative',
        width: '15px',
        height: '15px',
        margin: 0,
        borderRadius: '50%',
        right: '0px',
        backgroundColor: 'red',
    },
    placeholder_wrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '600px',
        paddingBottom: 0,
        margin: '0 auto',
    },
    placeholder_wrapper_img: {
        position: 'absolute',
        width: '1100px',
        height: '500px',
    },
    qrcode_wrapper: {
        flexDirection: 'column',
        display: 'flex',
        paddingBottom: 5,
        justifyContent: 'space-between',
        textAlign: 'center',
        alignItems: 'center',
        padding: '25px 0 0 0', 
        width: '100%',
    },
    qrcode_wrapper_image: {
        width: '135px',
        height: '135px',
        alignItems: 'right',
    }
});



const ViewPDF = ({ objs, img_url, share_qr_code, translate }) => {

    let cat_with_weights = [];    // add only cats which need to show
    objs?.map((obj, i, arr) => (
        obj?.children?.map((m, i1) => {
            m?.children.map((a, i2) => {
                if ((a.category_display_name !== '') && (a.is_active && a.visible)) {
                    cat_with_weights.push(m.cid);
                }
                return null;
            })
            return null;

        })
    ));


    return (

        <Document>

            <Page wrap size="A4">
                <View style={styles.details_wrapper}>
                    <View style={styles.page}>
                        <View style={styles.section}>
                            <Image src={qx5x} />
                        </View>

                        <View style={styles.section}>
                            <Image src={sunrise} />
                        </View>
                    </View>

                    {(img_url) && <View style={styles.placeholder_wrapper}>
                        <Image style={styles.placeholder_wrapper_img} allowDangerousPath src={img_url} />
                    </View>}
                </View>
            </Page>

            <Page wrap size="A4">
                <View style={styles.details_wrapper}>

                    <View style={styles.details_title}>
                        <Text style={styles.details_titleH1}>
                            {translate[0]?.My_QS5_X}
                        </Text>

                    </View>


                    {
                        objs?.map((obj, i) => (
                            <View key={i} style={(i === 0) ? styles.details_outer_wrapper : styles.details_outer_wrapper2}>
                                <View style={styles.details_first_column}>
                                    <Text>
                                        {obj.category_display_name}
                                    </Text>
                                </View>
                                <View style={styles.edit_col}>
                                    {(typeof obj.children !== 'string') &&
                                        obj?.children?.map((m, i) => (
                                            <React.Fragment key={'con-' + i}>
                                                {(cat_with_weights.includes(m.cid)) ? <>
                                                    <View key={i} style={styles.details_second_column}>
                                                        <View style={styles.view_category_name}>
                                                            <Text style={styles.details_third_column_text}>
                                                                {m.category_display_name}
                                                            </Text>
                                                        </View>
                                                        <View style={styles.view_category_list} >
                                                            {
                                                                m?.children.map((a, i1) => (
                                                                    (a.category_display_name !== '') ?
                                                                        <React.Fragment key={'con-' + i + i1}>
                                                                            {
                                                                                (a.is_active && a.visible) && <View key={i + i1} style={styles.details_third_column}> <Text style={styles.details_third_column_text}>{a.category_display_name}</Text>
                                                                                    {((a.is_active === true) && (a.icon)) ? <Image style={styles.color} allowDangerousPath src={a.icon} /> : <></>
                                                                                    }
                                                                                </View>
                                                                            }

                                                                        </React.Fragment> : <View style={{ display: 'none' }}></View>
                                                                )
                                                                )
                                                            }
                                                        </View>
                                                    </View></> : <></>}

                                            </React.Fragment>
                                        ))}
                                </View>

                            </View>
                        ))
                    }

                    {/* <View style={styles.details_title}>
                    </View> */}

                    <View style={styles.qrcode_wrapper}>
                        <Text>
                            {translate[0]?.SHARE_PDF_QRCODE}
                        </Text>
                    </View>
                    <View style={styles.qrcode_wrapper}>
                        <View>
                            <Image style={styles.qrcode_wrapper_image} allowDangerousPath src={share_qr_code} />
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
};

export default ViewPDF;
