import * as THREE from 'three';
import { GradientTexture, useTexture } from "@react-three/drei";
import spacer from '../../assets/space.png';

const MeshComp = (props) => {
    const textureURL = props.texture ? props.texture : spacer;

    const texture = useTexture([ textureURL, textureURL ])

    const num = 1;
    texture[num].wrapS = THREE.RepeatWrapping;
    texture[num].wrapT = THREE.RepeatWrapping;
    texture[num].repeat = {x: props.x, y: props.y}

    return (
        <>
            { props.material === "Frame_Sticker" ? 
                <meshStandardMaterial 
                    attach={props.material}
                    roughness={props.roughness} 
                    metalness={props.metalness}
                />
            : 
                <meshStandardMaterial
                    color={props.color} 
                    roughness={props.roughness} 
                    metalness={props.metalness} 
                    map={texture[num]} 
                />
            }
        </>
    );
}
 
export default MeshComp;