import MainRoutes from "../routes/routes";
import { HelmetProvider } from 'react-helmet-async';

import './App.scss';
import Header from "./Header";
import { DataProvider } from "../context/DataContext";
import Overlay from '../components/ui/Overlay'
import { useControls } from 'leva';
import { GUI } from '../leva/gui';

function App() {

	const gui = useControls('Custom Settings', GUI);

	const body = document.querySelector('body')
	body.style.setProperty("--bg-color1", gui.bgcolor1 ? gui.bgcolor1 : '#385769');
	body.style.setProperty("--bg-color2", gui.bgcolor2 ? gui.bgcolor2 : '#1a2f3b');
	body.style.setProperty("--bg-color1-gra", gui.bg_gradient_color1 ? gui.bg_gradient_color1 : '0%');
	body.style.setProperty("--bg-color2-gra", gui.bg_gradient_color2 ? gui.bg_gradient_color2 : '42%');
	body.style.setProperty("--bg-color-left", gui.bg_position_left ? gui.bg_position_left : '0px');
	body.style.setProperty("--bg-color-top", gui.bg_position_top ? gui.bg_position_top : '270px');

	return (
		<>
			<HelmetProvider>
				<div className='bg-overlay'></div>
				<DataProvider>
					<Overlay />
					<Header />
					<MainRoutes />
				</DataProvider>
			</HelmetProvider>
		</>
	);
}

export default App;
