import { useState, useContext } from 'react';
import * as THREE from 'three';

import DataContext from '../../context/DataContext';
import { searchObj, arrayTransform, showHideButtons } from '../../helpers/common_helper';

import '../../components/ui/IconsLayout.scss';

export const Button = (p) => {
	const { objs, dispatch, model, modelDispatch, hotspotsObj, dispatchHotspots, layoutOptions, dispatchLayoutOptions } = useContext(DataContext);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const load_dependent_parts = (obj) => {
		const active_buttons = [];
		const active_ids = [];

		objs?.map((m, n) => (
			m.children?.map((i, j) => {
				active_buttons.push(
					...i.children
						.map(a => a.is_active === true ? a : '')
						.filter(f => f !== '')
				);
				active_ids.push(
					...i.children
						.map(a => a.is_active === true ? a.category_id : '')
						.filter(f => f !== '')
				);
			})
		))
		
		obj.map((parts)=>{
			let flag = true;
			const Depend_ID = [];
			parts.dependency_array.map((depend)=>{
				if(active_ids.indexOf(depend) === -1){
					flag = false;
					
					let parent = depend.split(".");
					let parent_id = parent[0]+'.'+parent[1];
					
					const objID = searchObj("category_id", objs, parent_id);
					Depend_ID.push(
						...objID.children
						.map(m => m.is_active === true ? m.category_id : '')
						.filter(f => f !== '')
					);
				}

			})
			// console.log(Depend_ID);

			if(Depend_ID.length){
				flag = true;
			}

			Depend_ID.map((depend)=>{
				if(parts.dependency_array.indexOf(depend) === -1){
					flag = false;
				}
			})

			parts.is_active = flag;
		})
	}

	const handleClick = (event, className) => {
		if (!className.includes('active')){
			dispatchLayoutOptions({ type: 'SET_PARTS_LOADING', setvalue: false });
			dispatchLayoutOptions({ type: 'SET_LOADING', setvalue: true });
		}

		const newObj = objs;
		const newModel = model;
		const newHotspot = hotspotsObj;
		const parentObj = searchObj ("category_id", objs, p.parent_id);
		const isActive = searchObj ("is_active", parentObj, true);

		/* Logic to make options active, in active and show hide options. */
		/* Start */		
		if(isActive){
			arrayTransform(
				newObj, 
				"category_id", isActive.category_id, 
				["css_classes", "is_active"], ["m-2", false], 
				"children", "empty"
			);
		}
		
		arrayTransform(
			newObj, 
			"category_id", p.category_id, 
			["css_classes", "is_active"], ["m-2 active", true], 
			"children", "empty"
		);
		
        // showHideButtons() to show and hide buttons
        dispatch({ type: 'SET_OBJECT', obj: showHideButtons(newObj) });
		/* p?.config?.show?.map((o) => (
			arrayTransform(newObj, "category_id", o, "visible", true, "children", "empty")
		))
		p?.config?.hide?.map((o) => {
			const obj = searchObj ("category_id", newObj, o);
			
			if(obj.component === 'color-button'){
				arrayTransform(newObj, "category_id", o, ["visible", "css_classes"], [false, "m-2"], "children", "empty")
			}
			else{
				arrayTransform(newObj, "category_id", o, ["visible", "is_active", "css_classes"], [false, false, "m-2"], "children", "empty")
			}
		})
		
		// Make hidden node as inactive 
		newObj.map((m, n)=>{
			if(m.category_id === parentObj.parent_id){
				m.children.map((i, j)=>{		
					let catId = null;	
					i.children.map((a,b)=>{
						if(a.component === 'button'){
							if(a.visible === true){						
								if(catId === null){
									catId = a.category_id;
								}
								if(a.is_active === true){
									catId = a.category_id;
								}
							}
							
							// Make node as inactive 
							arrayTransform(
								newObj, 
								"category_id", a.category_id, 
								["css_classes", "is_active"], ["m-2", false], 
								"children", "empty"
							)
						}
					})

					if(catId){
						// Make node as active
						arrayTransform(
							newObj, 
							"category_id", catId, 
							["css_classes", "is_active"], ["m-2 active", true], 
							"children", "empty"
						)

						
					}
				})
			}
		})

		dispatch({
			type: 'SET_OBJECT',
			obj: newObj
		}); */
		/* End  */

		// Change camera angle

		const noneBtn = document.getElementById('8.1.1')

		if(p.category_id === "8.1.0"){
			if(noneBtn.classList.contains("active")){
				layoutOptions[0].cam?.current?.setLookAt(0, 3, -8, 0, 3, 0, true);
				dispatchLayoutOptions({ type: 'F55_INACTIVE', setvalue: true });
				dispatchLayoutOptions({ type: 'F55_FLOOR', setvalue: -0.31 });

				if (windowWidth < 1025) {
					layoutOptions[0].cam?.current?.reset(true);
					layoutOptions[0].cam?.current?.setLookAt(-11, 5, 13, 0, -1, 0, true);
				}
			}
		}

		// if(p.category_id === "8.1.1"){
		// 	if(!noneBtn.classList.contains("active")){
		// 		layoutOptions[0].cam?.current?.setLookAt(-11, 3, 2, 0, 3, 2, true);
		// 		dispatchLayoutOptions({ type: 'F55_ACTIVE', setvalue: true });

		// 		if (windowWidth < 769) {
		// 			layoutOptions[0].cam?.current?.reset(true);
		// 			layoutOptions[0].cam?.current?.setLookAt(-18, 5, 17, 0, -1, 2, true);
		// 		}
		// 	}
		// }
		
		// Hide unhide hotspots 
		newHotspot.map((h) =>{
			let hActive = true;
			if(h.main_cat.includes(parseInt(parentObj.parent_id))){
				newObj.map((m, n)=>{
					m.children.map((i, j)=>{
						i.children.map((a,b)=>{
							if(h.dependency_array.includes(a.category_id) && hActive){
								hActive = a.is_active;
							}
						})
					})
				})
				h.is_active = hActive;
			}
		})
		
		dispatchHotspots({
			type: 'SET_HOTSPOT',
			obj: newHotspot
		});


		/* Logic to Show hide GLB models based on options click. */
		/* Start */
		load_dependent_parts(newModel);
		// if (p.config.affected_categories){
		// 	load_dependent_parts(newModel, p.config.affected_categories, true);
		// }

		modelDispatch({
			type: "SET_MODEL",
			model: newModel
		});
		/* End */
	}

	return (
		<>
			<button id={p.category_id} onClick={event => (p.className !== 'm-2 active') ? handleClick(event, p.className) : ''} className={`w-100 full_width_button custom_active ${p.className} ${(p.config.visible) ? '' : 'b-hide'}`}>
				{p.children}
			</button>
		</>
	);
}

