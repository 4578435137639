import React, { useContext, useState } from 'react';
import './BlockLayout.scss';
import YourChoiceComponent from './YourChoiceComponent';
import DataContext from '../../../context/DataContext';

import './WeightComponent.scss';
import './BlockLayout.scss';

import closeicon from '../../../assets/images/close-icon.png';
import weighticon from '../../../assets/images/weight-icon.png';

function WeightComponent(props) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const { dispatchLayoutOptions, layoutOptions, hotspotsObj, dispatchHotspots, translate } = useContext(DataContext);
  
    let hide_choice = false;

    const displaybtn = () => {
        dispatchLayoutOptions({ type: 'SET_ICONS', setvalue: true });
        dispatchLayoutOptions({ type: "SET_BTNS", setvalue: true });
        dispatchLayoutOptions({ type: "SET_SUMMARY", setvalue: false });
        dispatchLayoutOptions({ type: "SET_WEIGHTS", setvalue: false });
        dispatchLayoutOptions({type: "SET_MOBILE_ICON_LAYOUT", setvalue: false});
        dispatchLayoutOptions({type: "SET_ICON_EFFECT",setvalue: true});

        const newHotspot = hotspotsObj;

        newHotspot.map((h, i) =>{
            h.is_active = h.is_default;
        })
    
        dispatchHotspots({
            type: "SET_HOTSPOT",
            obj: newHotspot
        })

        dispatchLayoutOptions({
            type: "HIDE_HOTSPOTS",
            setvalue: false
        })

        if(windowWidth < 1025 && windowWidth > 769){
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-13, 5, 15, 0, 3, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true)
        }
        else if(windowWidth < 769){
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-18, 5, 17, 0, 3, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true)
        }else{
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-11, 3, 2, 0, 3, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true)
        }      
    }

    return (
        <>
        <div className={`main_block moz_scroll ${layoutOptions[0].weights ? 'show' : ''} ${!layoutOptions[0].touchpoint && 'touchpoint_disabled'}`}>
            <div className='weight_wrapper'>
                <div className='block_title_area'>
                    <img className='pe-2' src={weighticon} alt='weighticon' />
                    <span>
                        {translate[0].Your_QS5_X}
                        <span className="text-orange ps-1">{translate[0].Weight}</span>
                    </span>
                    <img onClick={displaybtn} style={{cursor: 'pointer'}} src={closeicon}  alt='close' />   
                </div>

                <div className='weight_panel_block'>
                    <div className='total-weight-block pt-3'>
                        <div className='total-weight-label'>{translate[0].Total_Weight}</div>
                        <div className='total-weight-value'>
                            <span className='font-size-40'>{layoutOptions[0].total_weights}</span> {translate[0].weight_unit}
                        </div>
                    </div> 

                    <div className='total-weight-description'>
                        <p>{translate[0].weight_text}</p>
                    </div>

                    <YourChoiceComponent flag={hide_choice} weight_panel={'true'} />
                </div>               
            </div>
        </div>
        </>
    )
}

export default WeightComponent