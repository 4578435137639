import Accordion from 'react-bootstrap/Accordion';
import { renderComponent } from '../componentRenderer/RenderComponent';
import { arrayTransform } from '../../helpers/common_helper';
import { useContext, useState } from 'react';
import DataContext from '../../context/DataContext';

function AllCollapseExample(props) {
    const { objs, dispatch, layoutOptions } = useContext(DataContext);
    const [activeFilter, setActiveFilter] = useState('ALL');

    const listObjects = props.children;
    const newObj = objs;
    let count = [];

    listObjects.map((i, j)=>{
        count[i.category_id] = 0;
        i.children.map((a, b)=>{
            if(a.visible === true){
                count[i.category_id] = 1;
            }
        })    
    })

    const handleClick = (item, surface) => {
        setActiveFilter(surface);
        item.children.map((i, j)=>{
           if(surface.trim().toLowerCase() === 'all'){
                arrayTransform(newObj, "category_id", i.category_id, "visible", true, "children", "empty");
           }
           else{
                if(i.surface_finish === surface){
                    arrayTransform(newObj, "category_id", i.category_id, "visible", true, "children", "empty")
                }
                else{
                    arrayTransform(newObj, "category_id", i.category_id, "visible", false, "children", "empty")
                }
           }
        })
        
		dispatch({
			type: 'SET_OBJECT',
			obj: newObj
		});
    }

    return (
        <Accordion defaultActiveKey={0} className={`${!layoutOptions[0].touchpoint && 'touchpoint_disabled'}`}>
            {listObjects.map((item, index) => (
                <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header><span>{item.category_display_name}</span></Accordion.Header>
                    <hr className='accordion_border' />
                    <Accordion.Body>
                        {item.show_filter === 1 && 
                            <>
                                <div className='accordion_frame_color'>
                                    {item.filters.map((a, b) => (
                                        <button key={b} id={item.category_id+"_"+b} onClick={event => handleClick(item, a, b)} className={`${(count[item.category_id]) ? '' : 'b-hide'}${(activeFilter.toLowerCase() === a.toLowerCase()) ? 'active' : ''}`}> {item.filterNames[b]} </button>
                                    ))}
                                </div>
                            </>
                        }
                        
                        {item.not_available_name && <button className={`w-100 full_width_button active ${(count[item.category_id]) ? 'b-hide' : ''}`}> {item.not_available_name} </button> }                      
                        
                        {item.children.map((val, i) => (
                            renderComponent(val, item.category_id)
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default AllCollapseExample;