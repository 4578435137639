import { useContext } from 'react';

import DataContext from '../../context/DataContext';
import { searchObj, arrayTransform } from '../../helpers/common_helper';

import structuredClone from 'core-js/actual/structured-clone';

import '../../components/ui/IconsLayout.scss';

export const ColorButton = (p) => {
	const { objs, dispatch, model, modelDispatch } = useContext(DataContext);

	const handleClick = (event, partsArr, material, color, metalness, roughness, texture, repeat_x, repeat_y) => {
		const newObj = objs;
		const parentObj = searchObj ("category_id", objs, p.parent_id);
		const isActive = searchObj ("is_active", parentObj, true);
		const tempParts = structuredClone(model);

		/* Logic to make options active, in active */
		/* Start */		
		if(isActive){
			arrayTransform(
				newObj, 
				"category_id", isActive.category_id, 
				["css_classes", "is_active"], ["m-2", false], 
				"children", "empty"
			);
		}
		
		arrayTransform(
			newObj, 
			"category_id", p.category_id, 
			["css_classes", "is_active"], ["m-2 active", true], 
			"children", "empty"
		);

		dispatch({
			type: 'SET_OBJECT',
			obj: newObj
		});
		/* End */

		if(p.config.chair_color === true && p.config.default_option === false){
			const obj = searchObj("category_id", objs, '0.4').children.map(m => m.is_active === true ? m : '')
			.filter(f => f !== '');
			color = obj[0].hex;
			metalness = obj[0].metalness;
			roughness = obj[0].roughness;
			texture = obj[0].texture;
			repeat_x = obj[0].repeat_x;
			repeat_y = obj[0].repeat_y;
		}

		tempParts.map((o, i) => {
			if(partsArr.includes(o?.id)){
				o.materials.map((m, n) =>{
					if(material.includes(m)){
						tempParts[i].colors[n] = color;
						tempParts[i].metalness[n] = metalness;
						tempParts[i].roughness[n] = roughness;
						tempParts[i].texture[n] = texture;
						tempParts[i].repeat_x[n] = repeat_x;
						tempParts[i].repeat_y[n] = repeat_y;
					}
				})

			}
		});

		if(p.config.parent_id === "0.4"){
			const dependParts = [];
			const dependMaterials = [];
			newObj.map((m, n)=>{
				m.children.map((i, j)=>{	
					if(i.component === "div-color"){
						i.children.map((a,b)=>{
							if(a.chair_color === true && a.default_option === false && a.is_active === true){
								dependParts.push(...a.parts);
								dependMaterials.push(...a.glb_node_name);
							}
						})
					}
				})
			})

			tempParts.map((o, i) => {
				if(dependParts.includes(o?.id)){
					o.materials.map((m, n) =>{
						if(dependMaterials.includes(m)){
							tempParts[i].colors[n] = color;
							tempParts[i].metalness[n] = metalness;
							tempParts[i].roughness[n] = roughness;
							tempParts[i].texture[n] = texture;
							tempParts[i].repeat_x[n] = repeat_x;
							tempParts[i].repeat_y[n] = repeat_y;
						}
					})

				}
			});
		}

		modelDispatch({ type: "SET_MODEL", model: tempParts });
	}

	return (
		<>
			{p.config.chair_color == false && <div className={`color-btn-section ${(p.config.visible) ? '' : 'b-hide'} ${p.className}`}>
				<button id={p.category_id} title={p.config.category_display_name} style={{background : 'url('+p.config.icon+')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={event => (p.className !== 'm-2 active') ? handleClick(event, p.config.parts, p.config.glb_node_name, p.config.hex, p.config.metalness, p.config.roughness, p?.config.texture, p?.config.repeat_x, p?.config.repeat_y) : ''} className={`rounded_button`}>
				</button>
			</div>}

			{p.config.chair_color == true && 
				<button id={p.category_id} onClick={event => (p.className !== 'm-2 active') ? handleClick(event, p.config.parts, p.config.glb_node_name, p.config.hex, p.config.metalness, p.config.roughness, p?.config.texture, p?.config.repeat_x, p?.config.repeat_y) : ''} className={`w-100 full_width_button custom_active ${p.className} ${(p.config.visible) ? '' : 'b-hide'}`}>
					{p.children}
				</button>

			}
		</>
	);
}