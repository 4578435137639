import { useContext } from 'react';
import { Environment } from '@react-three/drei';

import { useControls } from 'leva';
import { GUI } from '../../leva/gui';

import DataContext from '../../context/DataContext';

const Environments = (props) => {
  const { environment, layoutOptions } = useContext(DataContext);

  const gui = useControls('Custom Settings', GUI);
  
  return (
    <>
      <Environment
        background={layoutOptions[0].toggle_env} 
        preset={!layoutOptions[0].toggle_env ? (environment === "null") ? null : environment : null}
        files={gui.hdr_url ? gui.hdr_url : process.env.REACT_APP_WEB_URL + "/hdr/modern_buildings_2_2k_32bit_FLAT5.hdr"}
        envMapIntensity = {5}
        resolution={256}
        ground={!layoutOptions[0].toggle_env ? false : {
          height: gui.height ? gui.height : 155, // Height of the camera that was used to create the env map (Default: 15)
          radius: gui.radius ? gui.radius : 2500, // Radius of the world. (Default 60)
          scale: gui.scale ? gui.scale : 100, // Scale of the backside projected sphere that holds the env texture (Default: 1000)
        }}
        // ground={{ height: 5, radius: 70, scale: 20 }}
        {...props} 
      />
    </>
  );
};

export default Environments;