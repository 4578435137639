import { useContext } from 'react';

import DataContext from '../../context/DataContext';
import Spinner from "../../assets/images/spinner.png";
import wheel from "../../assets/images/chair_loader.svg"
import LoaderLayout from './LoaderLayout';

const LoadingBlock = () => {
    const { layoutOptions } = useContext(DataContext);

    return (
        <>
            {layoutOptions[0].initialLoading && <LoaderLayout />}

            {!layoutOptions[0].initialLoading &&
                <div
                    style={{
                        textAlign: "center",
                        color: "#fff",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        padding: "25px",
                        background: "url('" + wheel + "') no-repeat rgba(0,0,0,0.9) 36px 13px",
                        transition: "all 0.5s ease",
                        zIndex: 99999,
                        borderRadius: '20px',
                        opacity: layoutOptions[0].loading ? 1 : 0
                    }}
                    className={`${!layoutOptions[0].loading && 'hide_loader'}`}
                >
                    <img className='loading-inner' src={Spinner} alt="" style={{ display: "inline-block", position: 'relative', top: '6px', }} />
                </div>
            }
        </>
    );
}

export default LoadingBlock;