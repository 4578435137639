import React, { useState, useEffect, useContext } from 'react';

import DataContext from '../../../context/DataContext';
import './YourChoiceComponent.scss';
import edit_icon from '../../../assets/images/edit-icon.png';
import { calculateWeight } from '../../../helpers/common_helper';

function YourChoiceComponent(props) {

    const { objs, dispatchLayoutOptions, translate, layoutOptions } = useContext(DataContext);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, [])

    let cat_with_weights = [];    // add only cats which need to show
    objs?.map((obj, i, arr) => (
        obj?.children?.map((m, i1) => (
            m?.children.map((a, i2) => {
                if ((!cat_with_weights.includes(obj.cid)) && (parseFloat(a.weight) > 0) && (a.css_classes.includes("active"))) {
                    cat_with_weights.push(obj.cid);

                }
                return null;
            })

        ))
    ));


    const showCat = (val, val2) => {
        dispatchLayoutOptions({ type: 'SET_ICONS', setvalue: true });
        dispatchLayoutOptions({ type: "SET_BTNS", setvalue: true });
        dispatchLayoutOptions({ type: "SET_SUMMARY", setvalue: false });
        dispatchLayoutOptions({ type: "SET_WEIGHTS", setvalue: false });
        dispatchLayoutOptions({ type: "SET_MOBILE_ICON_LAYOUT", setvalue: false });

        const icon = document.querySelector('.icons-' + val);
        icon?.click();

        const icon2 = document.querySelector('.block_' + val + ' .accordion-item:nth-child(' + (val2 + 1) + ') button');
        if (icon2.getAttribute('aria-expanded') === "false") {
            icon2.setAttribute('aria-expanded', true)
            icon2?.click();
        }

        if (windowWidth < 769) {
            const icon2 = document.querySelector('.icon_' + val);
            icon2?.click();

            setTimeout(() => {
                const icon3 = document.querySelector('.mob_' + val + ' .icons_title_area .icon_check .right_arrow');
                icon3?.click();

                const icon4 = document.querySelector('.mob_' + val + ' .accordion-item:nth-child(' + (val2 + 1) + ') button');
                if (icon4.getAttribute('aria-expanded') === "false") {
                    icon4.setAttribute('aria-expanded', true)
                    icon4?.click();
                }
            }, 0.1)
        }
    }

    return (
        <div className='your_choice_outer_wrapper'>
            <div className='choice_wrap'>
                {(props.flag) ?
                    <div className='choice_block'>
                        {translate[0].Your_Choices}
                    </div> : ''
                }
                <div className="choice_pdf">{props.downloadPDFButton}</div>
            </div>
            {(props.weight_panel === "true") ?
                <>
                    {
                        objs?.map((obj, i, arr) => {



                            return (
                                <div key={i} className='choice_items'>
                                    {
                                        <>
                                            {(cat_with_weights.includes(obj.cid)) ?
                                                <div className='choice_title'>
                                                    {obj.category_display_name}
                                                </div>
                                                :
                                                <></>
                                            }
                                            {
                                                (typeof obj.children !== 'string') &&
                                                obj?.children?.map((m, i1, arr1) => {

                                                    return (
                                                        <div key={i1}>
                                                            {
                                                                m?.children.map((a, i2, arr2) => {

                                                                    // 

                                                                    return (

                                                                        (a.is_active === true) && (a.category_display_name !== "") && (a.weight > 0) && (a.css_classes.includes("active")) &&

                                                                        <React.Fragment key={i2}>

                                                                            <div className='choice_children' >
                                                                                <div className='choice_children_type'>
                                                                                    {(a.weight_label !== '') ? a.weight_label : (m.category_display_name + " " + a.category_display_name)}
                                                                                </div>
                                                                                <div className="choice_children_content">
                                                                                    {calculateWeight(a.weight, translate[0].weight_unit)} {translate[0].weight_unit} <img className='ps-2 cursor-pointer' onClick={() => showCat(i, i1)} src={edit_icon} alt="edit" />
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }

                                                        </div>)
                                                })
                                            }
                                        </>
                                    }
                                </div>)


                        })
                    }
                </>
                :
                <>
                    {
                        objs?.map((obj, i) => (
                            <div key={i} className='choice_items'>
                                <div className='choice_title'>
                                    {obj.category_display_name}
                                </div>
                                {
                                    (typeof obj.children !== 'string') &&
                                    obj?.children?.map((m, i1) => (
                                        (m.category_display_name !== '') ?
                                            <div key={i1} className='choice_children'>
                                                <div className='choice_children_type'>
                                                    {m.category_display_name}
                                                </div>
                                                <div className="choice_children_content">
                                                    {
                                                        m?.children.map((a, i2) => (

                                                            (props.flag) ?
                                                                <div key={i2}>
                                                                    {
                                                                        (a.is_active && a.visible) ?
                                                                            <span className='choice_children_description'>
                                                                                <span className='choice_description_title one-line'>
                                                                                    <span title={a.category_display_name}>{a.category_display_name}</span>
                                                                                </span>
                                                                                {(a.component === 'color-button' && !a.chair_color) ? <button className='summary_color_button' style={{ background: 'url(' + a.icon + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}></button> : <button className='summary_color_button' style={{ border: 'none', background: 'none' }}></button>}

                                                                                <img className='ps-2 cursor-pointer' onClick={() => showCat(i, i1)} src={edit_icon} alt="edit" />
                                                                            </span>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>

                                                                :

                                                                <div key={i2}>
                                                                    {
                                                                        a.is_active === true ? <span className='choice_children_description'>
                                                                            0.00 KG <img className='ps-2 cursor-pointer' onClick={() => showCat(i, i1)} src={edit_icon} alt="edit" /></span> : ''
                                                                    }
                                                                </div>



                                                        )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            : <div style={{ display: 'none' }}></div>
                                    ))
                                }

                            </div>
                        ))
                    }
                </>
            }


        </div>
    )
}

export default YourChoiceComponent