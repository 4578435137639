import { useLoader } from '@react-three/fiber'
import { TextureLoader } from "three/src/loaders/TextureLoader";

function degrees_to_radians(degrees){
    var pi = Math.PI;
    return degrees * (pi/180);
  }

const Floor = (props) => {
    const colorMap = useLoader(TextureLoader, props.url ? props.url : process.env.REACT_APP_WEB_URL + "/floor/floor_final.png")

    return (
        <mesh rotation={[degrees_to_radians(-90),0,0]} {...props} >
            <planeGeometry args={[props.x ? props.x : 150, props.y ? props.y : 150]}  />
            <meshBasicMaterial map={colorMap} transparent={true} depthWrite={true} />
        </mesh>
    );
}
 
export default Floor;