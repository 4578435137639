import { React , useContext } from 'react';

import DataContext from '../../context/DataContext';

function ButtonConfigure(props) {
    const { dispatchLayoutOptions, translate } = useContext(DataContext);

    const displayButton = () => {
        dispatchLayoutOptions({
            type: "HIDE_HOTSPOTS",
            setvalue: false
        })

        dispatchLayoutOptions({ type: 'SET_ICONS', setvalue: true });
        dispatchLayoutOptions({ type: "SET_BTNS", setvalue: true });
        dispatchLayoutOptions({ type: "SET_SUMMARY", setvalue: false });
        dispatchLayoutOptions({ type: "SET_WEIGHTS", setvalue: false });
    }

    return (
        <div className={`button_wrapper ${props.hide}`}>
            <button  className='btn_backtoconfigure active' style={{margin: "0 5px"}} onClick={displayButton}>
                {translate[0].Back_To_Configure}
            </button>
        </div>
    )
}

export default ButtonConfigure