import React, { useEffect, useContext, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import YourChoiceComponent from './YourChoiceComponent';
import { faCircleChevronRight, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataContext from '../../../context/DataContext';
import { pdf } from '@react-pdf/renderer';
import ViewPDF from '../../pdf/ViewPDF';
import SocialMediaLayout from './SocialMediaLayout';
import copy from "copy-to-clipboard";
import { useSearchParams } from 'react-router-dom';
import DownloadOrderPdf from './DownloadOrderPdf.js';

import './SummaryComponent.scss';
import './BlockLayout.scss';

import closeicon from '../../../assets/images/close-icon.png';
import copyicon from '../../../assets/images/copy-icon.png';
import Spinner from "../../../assets/images/spinner.png";
import wheel from "../../../assets/images/chair_loader.svg"
import { saveAs } from 'file-saver';
import QRCode from 'qrcode';
import html2canvas from 'html2canvas';
import axios from 'axios';
import Floor from '../../Floor';

function SummaryComponent() {
    const [copied, setCopied] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const { objs, pdfImgObj, layoutOptions, dispatchLayoutOptions, translate, hotspotsObj, dispatchHotspots } = useContext(DataContext);

    const [loadurl, setLoadurl] = useState('');

    const ref = useRef(null);

    let hide_choice = true;

    const conf_name = "QS5-X";

    const [queryParameters] = useSearchParams();
    let urlParams = '';

    useEffect(() => {

        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
    }, [objs])

    const [toggle, setToggle] = useState(false);
    const [btnName, setBtnName] = useState('share');
    const navigate = useNavigate();

    var video_img = pdfImgObj?.domElement?.toDataURL('image/png', 0.1);

    const handleSubmit = (event) => {
        var formData = new FormData();
        // formData.append("share", loadurl);
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
        axios['get']('/v1/shareCode?share='+loadurl, JSON.parse(null), JSON.parse(null))
            .then((res) => {
                console.log(res);
                navigate(`${'/?' + res.data}`);
                event.preventDefault();
                window.location.reload();
            })
            .catch((err) => {
                console.log(err)
            });

        // navigate(`${'/?' + urlParams}`);
        event.preventDefault();
        // window.location.reload();
    };

    const copyToClipboard = () => {
        copy(layoutOptions[0].share_code);
        setCopied(true)

        setTimeout(() => {
            setCopied()
        }, 3000);
    }

    const handlebtnClick = (value, btn_Name) => {
        const setTabValue = (btnName === btn_Name) ? (toggle) ? 1 : value : value;
        setBtnName(btn_Name)
        dispatchLayoutOptions({
            type: "SET_SUMMARY_BTN",
            setvalue: setTabValue
        });

        setToggle(prev => !prev);

        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const orderPdf = () => {
        dispatchLayoutOptions({
            type: "ORDER_PDF",
            setvalue: true
        });

        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const displaybtn = () => {
        const newHotspot = hotspotsObj;

        newHotspot.map((h, i) => (
            h.is_active = h.is_default
        ))

        dispatchHotspots({
            type: "SET_HOTSPOT",
            obj: newHotspot
        })

        dispatchLayoutOptions({
            type: "HIDE_HOTSPOTS",
            setvalue: false
        })

        dispatchLayoutOptions({ type: 'SET_ICONS', setvalue: true });
        dispatchLayoutOptions({ type: "SET_BTNS", setvalue: true });
        dispatchLayoutOptions({ type: "SET_SUMMARY", setvalue: false });
        dispatchLayoutOptions({ type: "SET_WEIGHTS", setvalue: false });
        dispatchLayoutOptions({ type: "SET_MOBILE_ICON_LAYOUT", setvalue: false });
        dispatchLayoutOptions({ type: "SET_ICON_EFFECT", setvalue: true });

        if(windowWidth < 1025 && windowWidth > 769){
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-13, 5, 15, 0, 3, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true)
        }
        else if (windowWidth < 769) {
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-18, 5, 17, 0, 3, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true)
        } else {
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);

            layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-11, 3, 2, 0, 3, 2, true)
            layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true)
        }
    }

    const resetModel = () => {
        dispatchLayoutOptions({
            type: "HIDE_HOTSPOTS",
            setvalue: true
        })

        if (windowWidth < 769) {
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, -1.5, 0, true);
        } else {
            layoutOptions[0].cam.current?.reset(true);
            layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);
        }
    }

    const DownloadImageButton = () => (
        <button style={{ 'width': '100%' }}
            onClick={() => {

                dispatchLayoutOptions({ type: 'SET_LOADING', setvalue: true });

                if (windowWidth < 769) {
                    layoutOptions[0].cam.current?.reset(true);
                    layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true);

                    layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-18, 5, 17, 0, 3, 0, true)
                    layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true)
                } else {
                    layoutOptions[0].cam.current?.reset(true);
                    layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);

                    layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-11, 3, 2, 0, 3, 2, true)
                    layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true)
                }

                setTimeout(async () => {
                    html2canvas(document.querySelector("#three-canvas-container"), { allowTaint: true, useCORS: true, backgroundColor: "rgba(60, 70, 75, 0.8)" }).then(canvas => {


                        let imageBlob = canvas.toDataURL('image/jpeg', 1.0);
                        dispatchLayoutOptions({ type: 'SET_LOADING', setvalue: false });
                        saveAs(imageBlob, conf_name + '.jpg');
                    });
                }, 1000);
            }}
        >{translate[0].save_image}</button>
    );


    const DownloadPDFButton = () => (
        <FontAwesomeIcon icon={faFilePdf} style={{ cursor: 'pointer' }} onClick={
            () => {

                if (windowWidth < 769) {
                    layoutOptions[0].cam.current?.reset(true);
                    layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true);

                    layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-18, 5, 17, 0, 3, 0, true)
                    layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, 3, 0, true)
                } else {
                    layoutOptions[0].cam.current?.reset(true);
                    layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);

                    layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-11, 3, 2, 0, 3, 2, true)
                    layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true)
                }

                dispatchLayoutOptions({ type: 'SHOW_FLOOR', setvalue: 0 });
                dispatchLayoutOptions({ type: 'SET_LOADING', setvalue: true });
                setTimeout(async () => {
                    let urlParams = '';
                    queryParameters.forEach((value, key) => {
                        if (key !== 'share') {
                            urlParams = urlParams + '&' + key + '=' + value;
                        }
                    });
                    if (urlParams !== '') {
                        urlParams = urlParams.slice(1) + '&share=' + layoutOptions[0].share_code;
                    }
                    else {
                        urlParams = 'share=' + layoutOptions[0].share_code;
                    }
                    urlParams = urlParams + '&utm_medium=website&utm_source=visualiser&utm_campaign=qs5-x&utm_content=pdf';

                    let share_code_img = await QRCode.toDataURL(process.env.REACT_APP_WEB_URL + "?" + urlParams);
                    const doc = <ViewPDF objs={objs} img_url={pdfImgObj?.domElement?.toDataURL('image/png', 0.1)} share_qr_code={share_code_img} translate={translate} />;
                    const asPdf = pdf();
                    asPdf.updateContainer(doc);
                    const blob = await asPdf.toBlob();
                    dispatchLayoutOptions({ type: 'SET_LOADING', setvalue: false });
                    saveAs(blob, conf_name + '.pdf');
                    dispatchLayoutOptions({ type: 'SHOW_FLOOR', setvalue: 11 });

                }, 1000);
            }} />
    );

    return (
        <>
            <div className={`main_block moz_scroll ${layoutOptions[0].summary ? 'show' : ''} ${!layoutOptions[0].touchpoint && 'touchpoint_disabled'} ${layoutOptions[0].order_pdf && 'disable_scroll'}`}>
                {layoutOptions[0].order_pdf &&
                    <div>
                        <div style={{
                            textAlign: "center",
                            color: "#fff",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            padding: "25px",
                            background: "url('" + wheel + "') no-repeat rgba(0,0,0,0.9) 51% 52%",
                            transition: "all 0.5s ease",
                            zIndex: 99999,
                            borderRadius: '20px',
                            opacity: 1,
                        }}
                            className='spinner'
                        >
                            <img className='loading-inner' src={Spinner} alt="" style={{ display: "inline-block", position: 'relative', top: '50%', }} />
                        </div>
                    </div>
                }
                <div className='summary_wrapper' ref={ref} >
                    <div className='block_title_area'>
                        <span>
                            {translate[0].Your_QS5_X}
                            <span className="text-orange ps-1"> {translate[0].Summary}</span>
                        </span>
                        <img className='closeicon' style={{ cursor: 'pointer' }} onClick={displaybtn} src={closeicon} alt='close' />
                    </div>
                    <div className='summary_scroll'>
                        <div className={`share_section sub-block ${layoutOptions[0].summary_btn === 1 ? 'd-block' : 'd-none'}`} >
                            <div className='title-block'>
                                {translate[0].Share_your_chair}
                            </div>
                            {(layoutOptions[0].summary === true) && <div className='content-block'>
                                <div className='video-block' style={{ backgroundImage: `url(${video_img})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                </div>
                                {layoutOptions[0].save_code ? <span className='pt-4 d-block text-center' >Generating Social Media Icons ...</span> : <SocialMediaLayout /> }
                            </div>}
                        </div>

                        <div className={`load_section sub-block ${layoutOptions[0].summary_btn === 4 ? 'd-block' : 'd-none'}`}>
                            <div className='title-block'>
                                {translate[0].load_your_choice}
                            </div>
                            <div className='content-block'>
                                <p>{translate[0].load_choice_description}</p>
                                <div className='form-section pt-2'>
                                    <div className='form-label text-orange font-size-14 font-weight-800'>{translate[0].enter_your_code}</div>
                                    <div className='form-input'>
                                        <form onSubmit={handleSubmit} className='summary_form w-100'>
                                            <input type="text" value={loadurl} placeholder={translate[0].your_code} onChange={(event) => setLoadurl(event.target.value)} />
                                            <button className="btn_loadurlbtn" type="submit"><FontAwesomeIcon type="submit" icon={faCircleChevronRight} style={{ cursor: 'pointer' }} /></button>


                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`save_section sub-block ${layoutOptions[0].summary_btn === 3 ? 'd-block' : 'd-none'}`}>
                            <div className='title-block'>
                                {translate[0].save_for_later}
                            </div>
                            <div className='content-block'>
                                <p>{translate[0].save_description}</p>
                                <div className='QR-section pt-2'>
                                    <div className='QR-code'>
                                        <div className='text-orange font-size-14  font-weight-800'>{translate[0].enter_your_code}</div>
                                        <div className='QR-code-details pt-2'>
                                            <span className='QR-code-text font-size-24 font-weight-800' >{layoutOptions[0].save_code ? 'Generating Code...' : layoutOptions[0].share_code}</span>
                                            <img className='ps-3' style={{ cursor: 'pointer' }} src={copyicon} alt='copyicon' onClick={copyToClipboard} />
                                        </div>
                                        <div>
                                            {copied ? <p className='clipboard_copy'>{translate[0].copied}</p> : <p></p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                            <div className='two_btn_section'>
                                {!translate[0].test_drive_link && 
                                    <button className={layoutOptions[0].summary_btn === 1 ? 'active' : ''} style={{ width: "100%" }} onClick={() => handlebtnClick(1, 'share')}>{translate[0].Share}</button>
                                }

                                {translate[0].test_drive_link && 
                                    <>
                                        <button className={layoutOptions[0].summary_btn === 1 ? 'active' : ''} onClick={() => handlebtnClick(1, 'share')}>{translate[0].Share}</button>
                                        
                                        <a className='button' href={translate[0].test_drive_link} target='_blank' rel="noreferrer" onClick={() => handlebtnClick(1)}>{translate[0].Test_Drive}</a>

                                    </>
                                }
                            </div>

                        <div className='five_btn_section hr-line'>
                            <button className={layoutOptions[0].summary_btn === 3 ? 'active' : ''} onClick={() => handlebtnClick(3, 'save')}>{translate[0].Save}</button>
                            <button className={layoutOptions[0].summary_btn === 4 ? 'active' : ''} onClick={() => handlebtnClick(4, 'load')}>{translate[0].Load}</button>

                            {translate[0].showOrderPDF && !layoutOptions[0].save_code && <button onClick={() => orderPdf()}>{translate[0].order_form}</button>}
                            {layoutOptions[0].order_pdf === true && <DownloadOrderPdf />}

                            {translate[0].dealer_location_link && 
                                <a className='button' style={{ width: "100%" }} href={translate[0].dealer_location_link} target='_blank' rel="noreferrer" onClick={() => handlebtnClick(1)}>{translate[0].Find_Dealer}</a>
                            }
                                <DownloadImageButton />
                            
                        </div>
                        <YourChoiceComponent flag={hide_choice} downloadPDFButton={<DownloadPDFButton />} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SummaryComponent
