import * as THREE from 'three';
import React, { useLayoutEffect }from 'react';
import { useGLTF } from '@react-three/drei';
import MeshComp from './MeshComp';

const PartsMesh = (props) => {
	const { nodes, materials } = useGLTF(props.url);


    useGLTF.preload(props.url);
	const gltf = useGLTF(props.url);

    useLayoutEffect(() => {
        if (gltf.nodes){
            Object.keys(gltf.nodes).map((item, i) => {
                
                if (props.materials){
                    Object.keys(props.materials).map(mat => {
                        if (gltf.nodes[item].material.name === props.materials[mat]){
                            
                            // let color = "#151717";
                            // if(props.materials[mat] === "adskMatSpokes"){
							// 	if(props.colors[mat] === "#151717"){
							// 		color = "#98a1aa";
							// 	}
							// }else{
                            //    color = props.colors[mat];
                            // }
                            
							let color = props.colors[mat];

                            Object.assign(gltf?.materials[props.materials[mat]], {
                                color: new THREE.Color(color),
                                roughness: props.roughness[mat],
                                metalness: props.metalness[mat],
                            })
                        }
                    })
                }
            })
        }
    }, [gltf.scene, gltf.nodes, gltf.materials ]); 
     
	
	return (
		<>
			<group {...props} position={[0, 3.2, 0]} dispose={null}>
                {
                    Object.keys(nodes).map((item, i) => 
                        (
                            <mesh
                                key={i}
                                // castShadow
                                // receiveShadow
                                geometry={nodes[item].geometry}
                                material={materials[nodes[item].material.name]}
                                position={[nodes[item].position.x, nodes[item].position.y, nodes[item].position.z]}
                                rotation={[nodes[item].rotation.x, nodes[item].rotation.y, nodes[item].rotation.z]}
                                scale={[nodes[item].scale.x, nodes[item].scale.y, nodes[item].scale.z]}
                            >
                                { 
                                    props.materials &&
                                    Object.keys(props.materials).map(mat => (
                                        (nodes[item].material.name === props.materials[mat]) && 
                                            <MeshComp
                                                key={mat} 
                                                material={props.materials[mat]}
                                                color={props.materials[mat] === "adskMatSpokes" ?  (props.colors[mat] === "#151717") ? "#98a1aa" : "#151717" : props.colors[mat]} 
                                                roughness={props.roughness[mat]} 
                                                metalness={props.metalness[mat]}
                                                texture={props.texture[mat]}
                                                x={props.repeat_x[mat]}
                                                y={props.repeat_y[mat]}
                                            />
                                    ))
                                }
                                { 
                                    props.materials &&
                                    Object.keys(props.materials).map(mat => {
                                        return (props.materials[mat] === "adskMatSpokes") ? console.log(props.materials[mat]) : ""
                                    })
                                }
                            </mesh>
                        )
                    )
                }
			</group>

            {/* <mesh castShadow position={[0, 3.2, 0]} dispose={null}>
				{props.visible && <primitive object={gltf.scene} {...props} dispose={null} />}
			</mesh> */}
		</>
	)
}
 
export default PartsMesh;