import { OrbitControls, CameraControls } from '@react-three/drei'
import { useFrame, useThree, extend } from '@react-three/fiber'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react';

import DataContext from '../../context/DataContext';

extend({ OrbitControls });
const Cameras = () => {
  function degrees_to_radians(degrees){
    var pi = Math.PI;
    return degrees * (pi/180);
  }

  const { dispatchPdfImg, layoutOptions, dispatchLayoutOptions } = useContext(DataContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { camera, gl: { domElement } } = useThree();
  const cameraControlsRef = useRef();
  const maxDistance = (windowWidth < 769) ? 20 : 13;
  const minDistance = (windowWidth < 769) ? 8 : 7;
  const enabled = layoutOptions[0].touchpoint;
  const verticalDragToForward = false;
  const dollyToCursor = false;
  const infinityDolly = false;

  useEffect(()=> {
    const el = document.getElementById("three-canvas-container");
    el.style.opacity=1;
      
    if(windowWidth < 769){
      cameraControlsRef.current?.setPosition(0, 0, 0, false);
      cameraControlsRef.current?.rotate(degrees_to_radians(90), 0, false);
      cameraControlsRef.current?.dolly(2, false);
      cameraControlsRef.current?.truck(0, -3, false);
      cameraControlsRef.current?.zoom(-0.5, false);

      setTimeout(()=>{

        cameraControlsRef.current?.reset(true);
        cameraControlsRef.current?.setLookAt(-11, 5, 13, 0, 3, 0, true);
        // cameraControlsRef.current?.setLookAt(-11, 5, 13, 0, 3, -2, true);

        dispatchLayoutOptions({
          type: "SET_MOBILE_LEFT_PANEL",
          setvalue: true
        });

      }, 500)
    }else{
      cameraControlsRef.current?.setPosition(40, 100, 3, false);
      cameraControlsRef.current?.dolly(2, false);
      cameraControlsRef.current?.truck(0, -3, false);

      setTimeout(()=>{
        // cameraControlsRef.current?.setPosition(-7, 4, 9,true);

        cameraControlsRef.current?.reset(true);
        cameraControlsRef.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);
      }, 500)
    }

    // setTimeout(()=>{
      
    // }, 500)

    setTimeout(()=>{
      dispatchLayoutOptions({ type: 'SET_LOADING', setvalue: false });
      dispatchLayoutOptions({ type: 'SET_INIT_LOADING', setvalue: false });
    }, 450)

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    dispatchLayoutOptions({
        type: "SET_CAM",
        setvalue: cameraControlsRef
    });
  }, [])

  //code to move camera
  useFrame((state) => {
  })

  const gl = useThree((state) => state.gl);
  dispatchPdfImg({
    type: 'SET_PDF_IMG',
    pdfimg: gl
  });
  const handleChange = () =>{
    dispatchPdfImg({
      type: 'SET_PDF_IMG',
      pdfimg: gl
    });
  }

  
 
  return (
    <>
      <CameraControls
        ref={cameraControlsRef}
        minPolarAngle={degrees_to_radians(0)}
        maxPolarAngle={Math.PI / 2.05}
        maxDistance={maxDistance}
        minDistance={minDistance}
        enabled={enabled}
        smoothTime={0.3}
        truckSpeed={0}
        verticalDragToForward={verticalDragToForward}
        dollyToCursor={dollyToCursor}
        infinityDolly={infinityDolly}
        onEnd={(e) => handleChange(e)}
      />
    </>
  );
};

export default Cameras;