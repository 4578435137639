import { folder } from 'leva';
// import hdr from '../assets/modern_buildings_2_2k_32bit.hdr';

export const GUI = {
    Background: folder({
        'bgcolor1': "#385769",
        'bg_gradient_color1': {value: '0%', min: 0, max: 100, step: 1},
        'bgcolor2': "#1a2f3b",
        'bg_gradient_color2': {value: '42%', min: 0, max: 100, step: 1},
        'bg_position_left': {value: '0px', min: 0, max: 1000, step: 1},
        'bg_position_top': {value: '270px', min: 0, max: 1000, step: 1},
    }),
    /*Floor: folder({
        // 'shadow_img_url': `${process.env.REACT_APP_WEB_URL}/floor/roundshadow.png`,
        'shadow_img_url': `${process.env.REACT_APP_WEB_URL}/floor/floor_3.1.png`,
        'shadow_img_url': `https://cdn.pixabay.com/photo/2015/05/03/20/03/mosaic-751648_960_720.jpg`,
        // 'f_x': {value: 1.3, min: 0, max: 50, step: 0.5},
        // 'f_y': {value: 2, min: 0, max: 50, step: 0.5},
        'f_x': {value: 150, min: 0, max: 500, step: 0.5},
        'f_y': {value: 150, min: 0, max: 500, step: 0.5},
    }),*/
    // SSAO: folder({
    //     'samples': "64",
    //     'ssao_radius': "0.1",
    //     'intensity': "13",
    //     'rings': "6",
    //     'distanceThreshold': "0",
    //     'distanceFalloff': "1",
    //     'rangeThreshold': "0",
    //     'rangeFalloff': "1",
    //     'luminanceInfluence': "0",
    //     'ssao_scale': "1",
    //     'bias': "0.1",
    //     'ssao_color': "#5f5f5f",
    // }),

    // lighting: folder({
    //     directionalLight: folder({
    //         'active': true,
    //         'helper': true,
    //         'position': { x: 7, y: 11, z: 7 },
    //         'color': "#ffffff",
    //         'intensity': { value: 9, min: 0, max: 20, step: 1 }
    //     }),
    //     directionalLight1: folder({
    //         'active1': true,
    //         'helper1': true,
    //         'position1': { x: -7, y: 7, z: 4 },
    //         'color1': "#ffffff",
    //         'intensity1': { value: 8, min: 0, max: 20, step: 1 }
    //     }),
    //     directionalLight2: folder({
    //         'active2': true,
    //         'helper2': true,
    //         'position2': { x: 3, y: 11, z: -9 },
    //         'color2': "#ffffff",
    //         'intensity2': { value: 4, min: 0, max: 20, step: 1 }
    //     }),
    //     directionalLight3: folder({
    //         'active5': true,
    //         'helper4': true,
    //         'position5': { x: 0, y: 10, z: 0 },
    //         'color5': "#ffffff",
    //         'intensity5': { value: 7, min: 0, max: 20, step: 1 }
    //     }),
    //     pointLight: folder({
    //         'active3': false,
    //         'helper3': false,
    //         'position3': { x: 0.00, y: -1.00, z: 0.00 },
    //         'color3': "#ffffff",
    //         'intensity3': { value: 1, min: 0, max: 20, step: 1 }
    //     }),
    //     hemisphereLight: folder({
    //         'active4': false,
    //         'position4': { x: 0, y: 0, z: 0 },
    //         'color4_0': "#ffffff",
    //         'color4_1': "#ffffff",
    //         'intensity4': { value: 4, min: 0, max: 20, step: 1 }
    //     })
    // }),
    // 'Show stats': folder({
    //   showStats: "test2",
    // }),

    // Terrain: folder({
    //     'hdr_url': `${process.env.REACT_APP_WEB_URL}/hdr/modern_buildings_2_2k_32bit_FLAT5.hdr`,
    //     'height': "155",
    //     'radius': "2500",
    //     'scale': "100",       
    // }),
    // Hotspots: folder({
    //     'color':'#ee6100',
    //     'pos_x1': '-3.050',
    //     'pos_y1': "2.250",
    //     'pos_z1': "3.300",
    //     'pos_x2': '-0.800',
    //     'pos_y2': "3.950",
    //     'pos_z2': "2.300",
    //     'pos_x3': '3.600',
    //     'pos_y3': "3.950",
    //     'pos_z3': "2.250",     
    // }),
}