import { Routes, Route } from 'react-router-dom';

import Homepage from '../pages/Homepage';

function MainRoutes() {
	return (
		<main className="flex-shrink-0">
			<Routes>
				<Route path="/" element={ <Homepage /> } />
			</Routes>
		</main>
	);
}

export default MainRoutes;