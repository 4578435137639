export const layoutOptionsReducer = (state, action) => {
	switch (action.type) {
		case 'SET_INIT_LOADING':
			state[0].initialLoading = action.setvalue;
			return [...state]

		case 'SET_LOADING':
			state[0].loading = action.setvalue;
			return [...state]

		case 'SET_PARTS_LOADING':
			state[0].isPartsLoaded = action.setvalue;
			return [...state]

		case 'SET_ICONS':
			state[0].iconLayout = action.setvalue;
			return [...state]

		case 'SET_BTNS':
			state[0].buttonLayouts = action.setvalue;
			return [...state]

		case 'SET_WEIGHTS':
			state[0].weights = action.setvalue;
			return [...state]

		case 'SET_WEIGHTS_TOTAL':
			state[0].total_weights = action.setvalue;
			return [...state]

		case 'SET_SUMMARY':
			state[0].summary = action.setvalue;
			return [...state]

		case 'SET_SUMMARY_BTN':
			state[0].summary_btn = action.setvalue;
			return [...state]

		case 'SET_ICON_BTN':
			state[0].icon_btn = action.setvalue;
			return [...state]

		case 'SET_ENV':
			state[0].toggle_env = action.setvalue;
			return [...state]

		case 'SET_CAM':
			state[0].cam = action.setvalue;
			return [...state]

		case 'SET_SHARE_CODE':
			state[0].share_code = action.setvalue;
			return [...state]
			
		case 'SET_SHARE_IMG':
			state[0].share_img = action.setvalue;
			return [...state]

		case 'SET_ACTIVE_STATE':
			state[0].active_state = action.setvalue;
			return [...state]

		case 'SET_PARTS_PROPS':
			state[0].set_parts_props = action.setvalue;
			return [...state]

		case 'SET_HOTSPOTS':
			state[0].hotspots = action.setvalue;
			return [...state]

		case 'SET_HOTSPOTS_BTN':
			state[0].hotspots_btn = action.setvalue;
			return [...state]

		case 'SET_MOBILE_ICON_LAYOUT':
			state[0].mobile_icon_layout = action.setvalue;
			return [...state]

		case 'SET_MOBILE_LEFT_PANEL':
			state[0].mobile_left_panel = action.setvalue;
			return [...state]

		case 'SET_TOUCHPOINT':
			state[0].touchpoint = action.setvalue;
			return [...state]

		case 'SET_HOTSPOT_TYPES':
			state[0].hotspot_types = action.setvalue;
			return [...state]

		case 'SET_ICON_EFFECT':
			state[0].iconEffect = action.setvalue;
			return [...state]

		case 'TERMS_PANEL':
			state[0].terms_panel = action.setvalue;
			return [...state]

		case 'HIDE_HOTSPOTS':
			state[0].hide_hotspots = action.setvalue;
			return [...state]

		case 'F55_ACTIVE':
			state[0].f55_active = action.setvalue;
			return [...state]

		case 'ORDER_PDF':
			state[0].order_pdf = action.setvalue;
			return [...state]

		case 'F55_INACTIVE':
			state[0].f55_inactive = action.setvalue;
			return [...state]

		case 'SET_SAVE_CODE':
			state[0].save_code = action.setvalue;
			return [...state]

		case 'SHOW_FLOOR':
			state[0].show_floor = action.setvalue;
			return [...state]

		case 'F55_FLOOR':
			state[0].f55_floor = action.setvalue;
			return [...state]

		case 'SET_VISUALISER':
			state[0].visualiser = action.setvalue;
			return [...state]

		default:
			return state
	}
}