import { useContext } from "react";
import "./IconsLayout.scss";
import closeicon from '../../assets/images/close-icon.png';
import DataContext from "../../context/DataContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";


const Terms = () => {
    const { translate, layoutOptions, dispatchLayoutOptions } = useContext(DataContext);

    const toggleBlock = () => {
        dispatchLayoutOptions({type: "TERMS_PANEL", setvalue: false});
    }

    const closeBlock = () => {
        dispatchLayoutOptions({type: "TERMS_PANEL", setvalue: true});
    }
  
    return (
        <div className={`terms_wrapper ${!layoutOptions[0].terms_panel ? 'active' : 'inactive'}`}>
            <div className='terms_block'>
                <div className='terms_title' onClick={toggleBlock}>
                    {translate[0].Terms_And_Conditions}
                </div>
                <div className='terms_content'>
                    <ReactMarkdown linkTarget="_blank">
                        {translate[0].tnc_text}
                    </ReactMarkdown>
                    
                </div>
            </div>
            <div className={`term_close ${!layoutOptions[0].terms_panel ? 'active' : 'inactive'}`} onClick={closeBlock}>
                <img style={{cursor: 'pointer'}} src={closeicon}  alt='close' />
            </div>
        </div>
        
    )
}

export default Terms