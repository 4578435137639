import { useContext, useState, useRef, useEffect } from 'react';
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/three'

import DataContext from '../../context/DataContext';
import { useFrame } from 'react-three-fiber';
import { Html } from "@react-three/drei"

import './Hotspots.scss';

const Hotspots = ({ posi_value, mat_color, click_val, prop, rotation, p, name }) => {

    const [active, setActive] = useState(0)
    const [display, setDisplay] = useState('hide')
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [circleArgs, setCircleArgs] = useState([0.10, 32, 32])
    const [torusArgs, setTorusArgs] = useState([0.3, 0.01, 12, 36])

    const { layoutOptions, dispatchLayoutOptions, hotspotsObj } = useContext(DataContext);

    useEffect(() => {
        if(windowWidth < 769){
            setCircleArgs([0.25, 32, 32]);
            setTorusArgs([0.50, 0.01, 12, 36]);
        }
    }, [])

    const { spring } = useSpring({
        spring: active,
        config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 }
    })

    const scale = spring.to([0, 5], [1, 6])
    const scale2 = spring.to([0, 25], [1, 6])

    const handleClick = (click_val) => {

        dispatchLayoutOptions({
            type: "SET_HOTSPOTS",
            setvalue: true
        });

        dispatchLayoutOptions({
            type: "SET_HOTSPOTS_BTN",
            setvalue: click_val
        });

        dispatchLayoutOptions({
            type: "SET_TOUCHPOINT",
            setvalue: false
        });

        hotspotsObj.map((h, i) =>
        {
            if(click_val === h.id){
                dispatchLayoutOptions({
                    type: "SET_HOTSPOT_TYPES",
                    setvalue: {      
                        textType: h?.type,
                        textName: h?.name,
                        textContent: h?.content,
                        imgLink: h?.src,
                        url: h?.url,
                    }
                });
            }
        }
        )
    }

    const hotspotRef = useRef();
    const hotspotRef2 = useRef();
    useFrame(({ camera }) => {
        if(camera.rotation){
            hotspotRef.current.rotation.x = camera.rotation.x;
            hotspotRef.current.rotation.y = camera.rotation.y;
            hotspotRef.current.rotation.z = camera.rotation.z;
            hotspotRef2.current.rotation.x = camera.rotation.x;
            hotspotRef2.current.rotation.y = camera.rotation.y;
            hotspotRef2.current.rotation.z = camera.rotation.z;
        }
    });

    const onHoverIn = () => {
        setActive(Number(!active))
        setDisplay('show')
    }

    const onHoverOut = () => {
        setActive(Number(!active))
        setDisplay('hide')
    }


    return (
        <>
            <a.group position={posi_value} ref={hotspotRef2}>
                <a.mesh scale-x={scale} scale-z={scale} scale-y={scale} onPointerOver={onHoverIn} onPointerOut={onHoverOut} onClick={() => handleClick(click_val)}>
                    <circleGeometry args={circleArgs} />
                    <a.meshBasicMaterial color={mat_color} />
                        <Html>
                            <div className={`hotspot_child ${display} ? 'show' : 'hide'`}>
                                {name}
                            </div>
                        </Html>
                </a.mesh>
            </a.group>

            <a.mesh scale-x={scale2} scale-z={scale2} scale-y={scale2} position={posi_value} ref={hotspotRef} onPointerOver={onHoverIn} onPointerOut={onHoverOut}>
                <torusGeometry args={torusArgs} />
                <meshBasicMaterial color={"#ffffff"} />
            </a.mesh>
        </>
    )
}

export default Hotspots