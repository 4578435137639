import { React, useState, useContext, useEffect } from 'react';
import { Base64 } from 'js-base64';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataContext from '../../context/DataContext';

import "./ButtonLayout.scss";
import weighticon from '../../assets/images/weight-icon.png';
import moreicon from '../../assets/images/more-icon.png';
import closelgicon from '../../assets/images/close-lg-icon.png';
import { arrayTransform, showHideButtons } from '../../helpers/common_helper';
import chatIcon from '../../assets/images/header_images/chat_icon.svg';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import structuredClone from 'core-js/actual/structured-clone';

const ButtonLayout = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobileButton, setMobileButton] = useState(false);
    const [queryParameters] = useSearchParams();

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
        setMobileButton(true);

        dispatchLayoutOptions({ type: 'SET_ICONS', setvalue: false });
        dispatchLayoutOptions({ type: "SET_ICON_EFFECT", setvalue: false })
    }
    const handleCLoseToggle = () => {
        setActive(!isActive);
        setMobileButton(true);

        dispatchLayoutOptions({ type: 'SET_ICONS', setvalue: true });
        dispatchLayoutOptions({ type: "SET_ICON_EFFECT", setvalue: true })
    }

    let i = 1;

    const { objs, dispatch, layoutOptions, dispatchLayoutOptions, translate, dispatchSvgImg, pdfImgObj } = useContext(DataContext);

    const downloadQRCode = () => {
        const qrCodeURL = document?.getElementById('qrCodeEl')
            ?.toDataURL("image/png")
            ?.replace("image/png", "image/octet-stream");

        dispatchSvgImg({ type: 'SET_SVG_IMG', svgimg: qrCodeURL });
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);

        if (windowWidth < 769) {
            setMobileButton(true)
        } else {
            setMobileButton(false)
        }
    }, [windowWidth])

    const displayPanel = (typeValue) => {
        const newObj = structuredClone(objs);

        // showHideButtons() to show and hide buttons
        dispatch({ type: 'SET_OBJECT', obj: showHideButtons(newObj) });

        dispatchLayoutOptions({ type: "HIDE_HOTSPOTS", setvalue: true })
        dispatchLayoutOptions({ type: "SET_SAVE_CODE", setvalue: true })

        setTimeout(() => {
            dispatchLayoutOptions({ type: typeValue, setvalue: true });
        }, 50);

        dispatchLayoutOptions({ type: 'SET_ICONS', setvalue: false });
        dispatchLayoutOptions({ type: "SET_ICON_BTN", setvalue: null });
        dispatchLayoutOptions({ type: "SET_ICON_EFFECT", setvalue: false })

        // Share image only on click of summary button 
        if (typeValue === "SET_SUMMARY") {
            // Get active button ids 
            let active_parts = [];
            objs?.map((m, n) => (
                m.children?.map((i, j) => {
                    active_parts.push(
                        ...i.children
                            .map(a => a.is_active === true ? a.category_id : '')
                            .filter(f => f !== '')
                    );
                })
            ))

            const active_encode = Base64.encodeURI(active_parts.toString());

            if (windowWidth < 1025 && windowWidth > 769) {
                layoutOptions[0].cam.current?.reset(true);
                layoutOptions[0].cam.current?.setLookAt(-10, 5, 12, 0, -2, 0, true);
    
                layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-13, 5, 15, 0, -2, 2, true)
                layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-10, 5, 12, 0, -2, 0, true)
            }
            else if (windowWidth < 769) {
                layoutOptions[0].cam.current?.reset(true);
                layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, -2, 0, true);
    
                layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-18, 5, 17, 0, -2, 2, true)
                layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-11, 5, 13, 0, -2, 0, true)
            } else {
                layoutOptions[0].cam.current?.reset(true);
                layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true);
    
                layoutOptions[0].f55_active && layoutOptions[0].cam.current?.setLookAt(-11, 3, 2, 0, 3, 2, true)
                layoutOptions[0].f55_inactive && layoutOptions[0].cam.current?.setLookAt(-6, 4, 7, 0, 3, 0, true)
            }

            // Save Image for share 
            var formData = new FormData();
            setTimeout(async () => {
                // console.log(pdfImgObj?.domElement?.toDataURL('image/png', 0.1));
                html2canvas(document.querySelector("#three-canvas-container"), { allowTaint: true, useCORS: true, backgroundColor: "rgba(60, 70, 75, 0.8)", scale: window.innerWidth > 1200 ? 0.3 : 1 }).then(canvas => {
                    // let imageBlob = canvas.toDataURL('image/jpeg', 1.0);
                    formData.append("FileData", canvas.toDataURL('image/jpeg', 1.0));
                    formData.append("shareCode", active_encode);
                    formData.append("visualizer", queryParameters.get("visualizer") ? queryParameters.get("visualizer") : 1);
                    formData.append("country", queryParameters.get("country") ? queryParameters.get("country") : "");
                    formData.append("lang", queryParameters.get("lang") ? queryParameters.get("lang") : "");

                    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
                    axios['post']('/v1/saveShareImg', formData, JSON.parse(null))
                    .then((res) => {
                        dispatchLayoutOptions({
                            type: 'SET_SHARE_CODE',
                            setvalue: res.data.share_code
                        });
                        dispatchLayoutOptions({
                            type: 'SET_SHARE_IMG',
                            setvalue: res.data.filepath
                        });
                        dispatchLayoutOptions({
                            type: "SET_SAVE_CODE",
                            setvalue: false
                        })
                    })
                    .catch((err) => {
                        console.log(err)
                    });
                });
            }, 1000)
        }
        downloadQRCode()
    }

    const toggleEnv = () => {
        dispatchLayoutOptions({
            type: 'SET_ENV',
            setvalue: !layoutOptions[0].toggle_env
        });
    }
    const dollyZoom = (value) => {
        layoutOptions[0].cam.current?.dolly(value, true)
    }

    const resetChair = () => {
        const reponse = window.confirm(translate[0].reset_alert);

        if (reponse) {
            window.location.reload();
        }
    }

    return (
        <div className={`button_wrapper ${!(layoutOptions[0].summary || layoutOptions[0].weights || layoutOptions[0].initialLoading) ? 'show' : 'hide'} ${!layoutOptions[0].touchpoint && 'touchpoint_disabled'}`}>
            <div className='desktop-button-layout'>
                <div className={isActive ? "d-none" : "d-block active"}>
                    {/* not required for feb release */}
                    {/* <div className='accessibilty_wrapper'>
                        <span className='btn_aaccessibility_options'>
                            <a>
                                <FontAwesomeIcon  icon="fa-plus-circle" style={{cursor: 'pointer'}} />
                            </a>
                            FONT SIZE
                            <a>
                                <FontAwesomeIcon  icon="fa-minus-circle" style={{cursor: 'pointer'}} />
                            </a>
                        </span>
                        <span className='btn_aaccessibility_options'>
                            <a>
                                <FontAwesomeIcon  icon="fa-plus-circle" style={{cursor: 'pointer'}} />
                            </a>
                            CONTRAST
                            <a>
                                <FontAwesomeIcon  icon="fa-minus-circle" style={{cursor: 'pointer'}} />
                            </a>
                        </span>
                    </div> */}
                    {/* end */}
                </div>
                <button onClick={() => resetChair()}>{translate[0].Reset}</button>
                <button className={layoutOptions[0].toggle_env ? 'active' : ''} onClick={toggleEnv}>{translate[0].Terrain}</button>
                {/* not required for feb release */}
                {/* <button className={`btn_accessibility ${isActive ? ' ' : 'btn_done'}`} onClick={handleToggle}>
                    {(!isActive) ? 'Done' : 'ACCESSIBILITY'}
                </button> */}
                {/* end */}

                <button className="btn_zoomout" onClick={() => dollyZoom(-1)}><FontAwesomeIcon icon={faMinus} style={{ cursor: 'pointer' }} /></button>
                <button className="btn_zoomin" onClick={() => dollyZoom(1)}><FontAwesomeIcon icon={faPlus} style={{ cursor: 'pointer' }} /></button>
            </div>

            <div className={`lower-res ${!isActive ? 'd-block ' : 'd-none'} ${mobileButton ? 'd-block' : 'd-none'}`} >
                <div className='mobile-button-layout'>
                    <button onClick={() => resetChair()}>{translate[0].Reset}</button>
                    <button onClick={toggleEnv}>{translate[0].Terrain}</button>
                    <button className="btn_zoomout" onClick={() => dollyZoom(-1)}>{translate[0].Zoom_out} -</button>
                    <button className="btn_zoomin" onClick={() => dollyZoom(1)}>{translate[0].Zoom_In} +</button>
                </div>
            </div>

            <div className='button-lower-res-wrapper'>
                <a className='more_btn' style={{ cursor: 'pointer', width: '50px' }}>
                    {(isActive) ? <img onClick={handleToggle} className='me-2 moreicon' src={moreicon} alt='moreicon' /> :
                        <img className='pe-2' onClick={handleCLoseToggle} src={closelgicon} alt='closelgicon' style={{ width: '50px' }} />}
                </a>
                {/* <div className='chat_icon'>
                    <img src={chatIcon} className='chaticon' alt="chat_icon" style={{width: '45px', height: '45px'}}/>
                </div> */}
                <button className={`btn_weight ${isActive ? ' ' : 'inactive_btn'}`} style={{ margin: "0 5px" }} onClick={() => displayPanel("SET_WEIGHTS")}>
                    <img className='pe-2 weight_icon' src={weighticon} alt='weighticon' />
                    <span className='weight_text'>{layoutOptions[0].total_weights} {translate[0].weight_unit}</span>
                </button>

                <button className={`btn_summary ${isActive ? ' ' : 'inactive_btn'}`} style={{ margin: "0 5px" }} onClick={() => displayPanel("SET_SUMMARY")}>
                    {translate[0].Summary}
                </button>
            </div>
        </div>
    )
}

export default ButtonLayout