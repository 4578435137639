import { createContext, useReducer } from 'react';

import { modelRecuder } from '../reducers/modelsReducer';
import { objRecuder } from '../reducers/objReducer';
import { pdfImageReducer } from '../reducers/pdfImageReducer';
import { svgImageReducer } from '../reducers/svgImageReducer';
import { environmentReducer } from '../reducers/environmentReducer';
import { layoutOptionsReducer } from '../reducers/layoutOptionsReducer';
import { translateRecuder } from '../reducers/translateReducer';
import { hotspotReducer } from '../reducers/hotspotReducer';
import { mainPartsReducer } from '../reducers/mainPartsReducer';

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
	const [objs, dispatch] = useReducer(objRecuder, []);

	const [model, modelDispatch] = useReducer(modelRecuder, []);

	const [mainParts, mainPartsDispatch] = useReducer(mainPartsReducer, []);

	const [pdfImgObj, dispatchPdfImg] = useReducer(pdfImageReducer, '');

	const [svgImgObj, dispatchSvgImg] = useReducer(svgImageReducer, '');

	const [environment, dispatchEnvironment] = useReducer(environmentReducer, 'city');

	const [layoutOptions, dispatchLayoutOptions] = useReducer(layoutOptionsReducer, [
		{
			'initialLoading': true,
			'isPartsLoaded': false,
			'iconLayout': true,
			'buttonLayouts': true,
			'weights': false,
			'total_weights': 0,
			'summary': false,
			'summary_btn': 1,
			'icon_btn': null,
			'toggle_env': false,
			'cam': null,
			'share_code': '',
			'share_img': '',
			'hotspots': false,
			'hotspots_btn': 1,
			'mobile_icon_layout': false,
			'mobile_left_panel': false,
			'touchpoint': true,
			'iconEffect': true,
			'terms_panel': true,
			'hide_hotspots': false,
			'f55_active': false,
			'f55_inactive': false,
			'order_pdf': false,
			'save_code': true,
			'show_floor': 11,
			'f55_floor': -0.31,
			'set_parts_props': {
				partId: [],
				material_name: [],
				color: '',
				texture: '',
				repeat_x: 1,
				repeat_y: 1,
				icon: '',
				metalness: 0,
				roughness: 0
			},
			'hotspot_types': {
				textType: [],
				textName: '',
				textContent: '',
				imgLink: '',
				url: '',
			},
			'visualiser': 1
		}
	])

	const [translate, dispatchTranslate] = useReducer(translateRecuder, [
		{
			"reset": "RESET",
			"terrain": "TERRAIN",
			"summary": "SUMMARY",
			"share": "SHARE",
			"test_drive": "TEST DRIVE",
			"save": "SAVE",
			"load": "LOAD",
			"create_pdf": "CREATE PDF",
			"find_dealer": "FIND DEALER",
			"back_to_configure": "Back To Configure"
		}
	])

	const [hotspotsObj, dispatchHotspots] = useReducer(hotspotReducer, []);

	return (
		<DataContext.Provider
			value={{
				objs, dispatch,
				model, modelDispatch,
				pdfImgObj, dispatchPdfImg,
				svgImgObj, dispatchSvgImg,
				environment, dispatchEnvironment,
				layoutOptions, dispatchLayoutOptions,
				translate, dispatchTranslate,
				hotspotsObj, dispatchHotspots,
				mainParts, mainPartsDispatch
			}}
		>
			{children}
		</DataContext.Provider>
	)
}

export default DataContext;