import { useContext } from 'react';
import closeicon from '../../assets/images/close-icon.png';
import DataContext from '../../context/DataContext';

import './Hotspots.scss';

const HotspotModal = (p) => {

    const { layoutOptions, dispatchLayoutOptions } = useContext(DataContext);

    const displaybtn = () => {
        dispatchLayoutOptions({ type: 'SET_HOTSPOTS', setvalue: false });
        dispatchLayoutOptions({ type: 'SET_TOUCHPOINT', setvalue: true });
    }

    let cssMainClass = '';
    let cssClass = '';

    if(layoutOptions[0].hotspot_types.textType.toLowerCase() === 'images'){
        cssMainClass = 'modal_video_wrapper modal_images_wrapper';
        cssClass = 'modal_image_block';
    }
    else if(layoutOptions[0].hotspot_types.textType.toLowerCase() === 'video'){
        cssMainClass = 'modal_video_wrapper';
        cssClass = 'modal_video_block';
    }
    else{
        cssMainClass = 'modal_video_wrapper modal_text_wrapper';
        cssClass = 'modal_text_block';
    }

    return (
        <div className={`${cssMainClass}`}>
            <div className={`modal_wrapper ${cssClass}`}>
                    <div className='modal_content'>
                        <h3>{layoutOptions[0].hotspot_types.textName}</h3>
                        <img className='closeicon' style={{ cursor: 'pointer' }} onClick={displaybtn} src={closeicon} alt='close' />
                    </div>
                    {layoutOptions[0].hotspot_types.textType.toLowerCase() === 'text' &&
                        <div className="modal_para_block"><p>{layoutOptions[0].hotspot_types.textContent}</p></div>
                    }
                    {layoutOptions[0].hotspot_types.textType.toLowerCase() === 'video' &&
                        <div className="modal_video_outer">
                            <div>
                                <iframe src={layoutOptions[0].hotspot_types.url} ></iframe>
                            </div>

                            <div>
                                <p>{layoutOptions[0].hotspot_types.textContent}</p>
                            </div>
                        </div>
                    }
                    {layoutOptions[0].hotspot_types.textType.toLowerCase() === 'images' &&
                        <>
                            <div className="modal_image_outer">
                                <div>
                                    <img src={layoutOptions[0].hotspot_types.imgLink} width="150" height="150" className='modal_image'/>
                                </div>
                                <div>
                                    <p>{layoutOptions[0].hotspot_types.textContent}</p>
                                </div>
                            </div>
                        </>
                    }                 
            </div>
        </div>

    )
}

export default HotspotModal