const Lights = (props) => {
    return (
      <>
        <directionalLight args={["#ffffff", 9 , 10]} position={[7, 11, 7]} near={0.1} far={100} castShadow/>
        <directionalLight args={["#ffffff", 8 , 10]} position={[-7, 7, 4]} near={0.1} far={100} />
        <directionalLight args={["#ffffff", 4 , 10]} position={[3, 11, -9]} near={0.1} far={100}/>
        <directionalLight args={["#ffffff", 7 , 10]} position={[0, 10, 0]} near={0.1} far={100}/>
      </>
    );
  };

  export default Lights;